import { FC } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from 'assets/logo.svg';
import AppBar from '@mui/material/AppBar';
import { Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LinkedIn } from '@mui/icons-material';

const RootBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

const FooterLink = styled(RouterLink)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  color: 'inherit',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  '&:not(:last-child)': {
    marginRight: theme.spacing(2),
  },
  '&:hover': {
    opacity: 0.8,
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
}));

interface FooterProps {
  content?: {
    brand: {
      image?: string;
      width: number;
      text?: string;
    };
    copy: string;
  };
}

export const Footer: FC<FooterProps> = () => {
  const content = {
    brand: { image: Logo, height: 50, text: 'QBS' },
    copy: '© 2023 QBS All rights reserved.',
    links: [
      { name: 'Dashboard', route: '/' },
      { name: 'Operation History', route: '/operation-history' },
      { name: 'Profile', route: '/user-profile' },
    ],
  };

  let brand: JSX.Element | string;

  if (content.brand.image) {
    brand = (
      <img
        src={content.brand.image}
        alt=""
        width="100%"
        height={content.brand.height}
      />
    );
  } else {
    brand = content.brand.text || '';
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: 'grey.900' }}>
      <Box sx={{ maxWidth: '100%', padding: { xs: '0 16px', md: '0 40px' } }}>
        <RootBox
          py={2}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <FooterLink to="/">{brand}</FooterLink>
          <Box mr={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              color="secondary.main"
              component="p"
              variant="caption"
              fontWeight="bold"
              mr={1}
            >
              Socials Networks:
            </Typography>
            <Link
              display="block"
              variant="body1"
              href="https://linkedin.com/company/qbs-ag"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <LinkedIn sx={{ color: 'secondary.main' }} />
              </Stack>
            </Link>
          </Box>
          <Typography
            color="secondary.main"
            component="p"
            variant="caption"
            gutterBottom={false}
          >
            {content['copy']}
          </Typography>
        </RootBox>
      </Box>
    </AppBar>
  );
};
