// created by Andrii
import { FC, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import SumsubWebSdk from '@sumsub/websdk-react';
import {
  useKycTokenQuery,
  useResetKycVerificationMutation,
  useGetUserQuery,
} from '../../services';
import { Loader } from '../../shared';
import { useServerError } from '../../hooks';
import LoadingButton from '@mui/lab/LoadingButton';

const storageAccessTokenKyc = localStorage.getItem('tokenKyc');

type KycFormTypes = {};
export const KycForm: FC<KycFormTypes> = () => {
  const [kycToken, setKycToken] = useState<string>('');
  const [isStorageKycToken, setIsStorageKycToken] = useState<string | null>(
    storageAccessTokenKyc
  );
  const [isResetVerification, setIsResetVerification] =
    useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const { data: userData, refetch: refetchUserData } = useGetUserQuery();

  const {
    data: kycTokenData,
    refetch: refetchKycTokenData,
    isFetching: kycTokenIsFetching,
    error: kycTokenError,
    isError: kycTokenIsError,
  } = useKycTokenQuery({}, { skip: !!isStorageKycToken });

  const [
    resetKysVerification,
    {
      isLoading: isLoadingResetKyc,
      isError: isErrorResetKys,
      error: errorResetKys,
      isSuccess: isSuccessResetKyc,
    },
  ] = useResetKycVerificationMutation();

  const handleTokenExpiration = () => {
    setKycToken('');
    setIsStorageKycToken(null);
    if (!isStorageKycToken) {
      refetchKycTokenData();
    }
  };

  const handleResetVerification = () => {
    resetKysVerification({});
  };

  useServerError({ isError: kycTokenIsError, error: kycTokenError });
  useServerError({ isError: isErrorResetKys, error: errorResetKys });

  useEffect(() => {
    if (isSuccessResetKyc) {
      setIsResetVerification(false);
    }
  }, [isSuccessResetKyc]);

  useEffect(() => {
    if (isVerified && !userData?.kycVerified) {
      refetchUserData();
    }
  }, [isVerified]);

  useEffect(() => {
    if (kycTokenData?.token) {
      setKycToken(kycTokenData.token);
      localStorage.setItem('tokenKyc', kycTokenData.token);
    }
  }, [kycTokenData]);

  useEffect(() => {
    if (storageAccessTokenKyc) {
      setKycToken(storageAccessTokenKyc);
    } else {
      handleTokenExpiration();
    }
  }, [storageAccessTokenKyc]);

  return (
    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 } }}>
      {kycTokenIsFetching || isLoadingResetKyc ? (
        <Loader pad={20} />
      ) : (
        kycToken && (
          <SumsubWebSdk
            accessToken={kycToken}
            expirationHandler={handleTokenExpiration}
            config={{
              lang: 'en-EN',
              i18n: {
                document: {
                  subTitles: {
                    IDENTITY: 'Upload a document that proves your identity',
                  },
                },
              },
              onMessage: (type: string, payload: any) => {
                console.log('payload', payload);
              },
              uiConf: {
                customCssStr:
                  ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--green-color) !important;\n  background-image: none !important;\n}',
              },
              onError: (error: any) => {
                console.error('WebSDK onError', error);
              },
            }}
            options={{ addViewportTag: false, adaptIframeHeight: true }}
            onMessage={(type: any, payload: any) => {
              console.log('onMessage', type, payload);
              if (payload?.reviewResult?.reviewAnswer === 'RED') {
                setIsResetVerification(true);
              }
              if (payload?.reviewResult?.reviewAnswer === 'GREEN') {
                setIsVerified(true);
              }
            }}
            onError={(data: any) => console.log('onError', data)}
          />
        )
      )}
      {isResetVerification && (
        <LoadingButton
          onClick={handleResetVerification}
          loading={isLoadingResetKyc}
          loadingPosition="end"
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          type="button"
        >
          Reset verification
        </LoadingButton>
      )}
    </Paper>
  );
};
