import {
  useSendEmailCodeMutation,
  useSendPhoneCodeMutation,
} from '../services';
import { useServerError } from './useServerError';

interface IUseSendVerificationCode {
  sendEmailCode: ({ email }: { email: string }) => void;
  sendPhoneCode: ({ phone }: { phone: string }) => void;
  isSuccessSending: boolean;
  isLoadingSending: boolean;
}

export const useSendVerificationCode = (): IUseSendVerificationCode => {
  const [
    sendEmailCode,
    {
      isError: isSendEmailCodeError,
      error: SendEmailCodeError,
      isSuccess: isSuccessSendEmailCode,
      isLoading: isLoadingSendEmailCode,
    },
  ] = useSendEmailCodeMutation();

  const [
    sendPhoneCode,
    {
      isError: isSendPhoneCodeError,
      error: SendPhoneCodeError,
      isSuccess: isSuccessSendPhoneCode,
      isLoading: isLoadingSendPhoneCode,
    },
  ] = useSendPhoneCodeMutation();

  const isSuccessSending = isSuccessSendEmailCode || isSuccessSendPhoneCode;
  const isLoadingSending = isLoadingSendEmailCode || isLoadingSendPhoneCode;

  useServerError({ isError: isSendEmailCodeError, error: SendEmailCodeError });
  useServerError({ isError: isSendPhoneCodeError, error: SendPhoneCodeError });

  return {
    sendEmailCode,
    sendPhoneCode,
    isSuccessSending,
    isLoadingSending,
  };
};
