// created by Andrii
import { FC, useEffect, useState } from 'react';
import UserForm from './user.form';
import { Tabs, Tab, Box, Grid } from '@mui/material';
import { KycForm } from './kyc.form';
import UserPasswordRecovery from './user.pasword.recovery';
import { Container } from 'shared/container/container';
import { Typography } from '@mui/material';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { Settings } from 'pages/settings/settings.page';
import Sidebar from './sidebar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PANEL_LIST = {
  USER_FORM: 0,
  PASSWORD_CHANGE: 1,
  KYC: 2,
  SETTINGS: 3,
};

type SubRoutes = 'profile' | 'password-change' | 'kyc' | 'settings';
type SubRouteIndexes = 0 | 1 | 2 | 3;

type PanelRouteNames = {
  [index: number]: SubRoutes;
};

const PANEL_ROUTES_NAMES: PanelRouteNames = {
  0: 'profile',
  1: 'password-change',
  2: 'kyc',
  3: 'settings',
};

type PanelRouteIndexes = {
  [key: string]: SubRouteIndexes;
};

const PANEL_ROUTES_INDEXES: PanelRouteIndexes = {
  profile: 0,
  'password-change': 1,
  kyc: 2,
  settings: 3,
};

type UserProfilePage = {};
export const UserProfile: FC<UserProfilePage> = () => {
  const [value, setValue] = useState<number>(PANEL_LIST.USER_FORM);
  const navigator = useNavigate();
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigator(PANEL_ROUTES_NAMES[newValue as number] as To);
  };

  useEffect(() => {
    const [rootPath, to] = location.pathname.split('/').filter(Boolean);
    if (rootPath === 'user-profile' && !to) {
      navigator(PANEL_ROUTES_NAMES[PANEL_LIST.USER_FORM]);
      setValue(PANEL_ROUTES_INDEXES[PANEL_LIST.USER_FORM] as number);
    } else if (rootPath === 'user-profile' && to) {
      const transformedTo = to.replace('/', '');
      setValue(PANEL_ROUTES_INDEXES[transformedTo as string] as number);
    }
  }, [location, navigator]);

  return (
    <Container>
      <Typography variant="h4" color="inherit" noWrap>
        User Profile
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="User info" {...a11yProps(PANEL_LIST.USER_FORM)} />
          <Tab
            label="Change password"
            {...a11yProps(PANEL_LIST.PASSWORD_CHANGE)}
          />
          <Tab label="KYC" {...a11yProps(PANEL_LIST.KYC)} />
          <Tab label="Settings" {...a11yProps(PANEL_LIST.SETTINGS)} />
        </Tabs>
      </Box>
      <Grid container spacing={5} sx={{ mt: 3 }}>
        <Grid item xs={12} md={8}>
          <TabPanel value={value} index={PANEL_LIST.USER_FORM}>
            <UserForm />
          </TabPanel>
          <TabPanel value={value} index={PANEL_LIST.PASSWORD_CHANGE}>
            <UserPasswordRecovery />
          </TabPanel>
          <TabPanel value={value} index={PANEL_LIST.KYC}>
            <KycForm />
          </TabPanel>
          <TabPanel value={value} index={PANEL_LIST.SETTINGS}>
            <Settings />
          </TabPanel>
        </Grid>
        <Sidebar title="Verified Details" />
      </Grid>
    </Container>
  );
};
