import {
  Divider,
  Paper,
  Typography,
  styled,
  Box,
  Button,
  Grid,
} from '@mui/material';
import React, { FC } from 'react';
import { Container } from 'shared/container/container';
import { ArrowRight } from '@mui/icons-material';
import KYCLogo from 'assets/kyc-logo.png';
import { useCheckKYC } from 'hooks';
import { useGetUserQuery } from 'services';
import { Link } from 'react-router-dom';
import { theme } from '../../assets/theme';
import { AccountConfirmationBtn, AvatarComponent } from '../../components';
import { Sidebar } from './sidebar';
import { ChartBlock } from './chart/chart.block';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`;

export const DashboardPage: FC = () => {
  const { isVerified } = useCheckKYC();

  const { data: userData } = useGetUserQuery();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexDirection: { sm: 'row', xs: 'column' },
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h4" color="inherit">
          Dashboard
        </Typography>
      </Box>
      <Section
        sx={{
          boxShadow: 'none',
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: { sm: 'nowrap', xs: 'wrap' },
          justifyContent: 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
            height: '100%',
          }}
        >
          <AvatarComponent width={90} height={90} disableUpload />
          <Typography
            color="inherit"
            noWrap
            align="left"
            whiteSpace={'pre-wrap'}
          >
            Welcome,
            <br />
            {userData?.name}
          </Typography>
          <Divider orientation="vertical" sx={{ minHeight: '90px' }} />
          <Box>
            <Typography
              color={theme.palette.grey['400']}
              noWrap
              align="left"
              whiteSpace={'pre-wrap'}
            >
              User ID
            </Typography>
            <Typography noWrap align="right" whiteSpace={'pre-wrap'}>
              {userData?.id}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
          ml={2}
        >
          <AccountConfirmationBtn onlyBtn variant={'contained'} />
        </Box>
      </Section>
      {!isVerified && (
        <Section
          elevation={4}
          sx={{
            mt: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            flexWrap: { sm: 'nowrap', xs: 'wrap' },
            justifyContent: { xs: 'center' },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <img src={KYCLogo} width="95px" height="95px" alt="kyc" />
            <Typography
              sx={{ typography: { md: 'h5', xs: 'h6' } }}
              color="inherit"
              noWrap
              align="left"
              whiteSpace={'pre-wrap'}
            >
              Currently all operations are disabled.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            ml={2}
          >
            <Typography
              sx={{ typography: { md: 'h6', xs: 'h6' } }}
              color="inherit"
              noWrap
              align="left"
              whiteSpace={'pre-wrap'}
            >
              To make them available you need to start or finish KYC
              verification process.
            </Typography>
            <Button variant="contained" sx={{ mt: 1 }}>
              <NavLink to="/user-profile/kyc">
                <Typography>Start verification</Typography>
                <ArrowRight />
              </NavLink>
            </Button>
          </Box>
        </Section>
      )}
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <ChartBlock />
        </Grid>
        <Sidebar />
      </Grid>
    </Container>
  );
};
