import { IMetaSkipTake } from './pagination.interface';

export enum WithdrawStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export type WithdrawStatus = 'PENDING' | 'APPROVED' | 'COMPLETED' | 'CANCELED';

export interface WithdrawItem {
  id: number;
  fiat: string;
  amount: string;
  userId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  cryptoAsset: string | null;
  ibanId: number;
  walletAddress: string | null;
}

export interface IWithdrawRequests {
  items: WithdrawItem[];
  meta: IMetaSkipTake;
}

export interface IUserIbans {
  id: number;
  account: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
  isVerified: boolean;
}
