// created by Artem
import { FC } from 'react';
import { DoNotDisturb } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { OperationHistoryRowComponent } from './operation-history-row.component';

type OperationHistoryTableComponentProps = {
  data?: any[];
};
export const OperationHistoryTableComponent: FC<
  OperationHistoryTableComponentProps
> = ({ data }) => {
  return (
    <>
      {data?.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 10,
          }}
        >
          <DoNotDisturb />
          <Typography ml={1}>You haven't got any operations yet.</Typography>
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">
                  <Typography fontWeight={600}>ID</Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography fontWeight={600}>Details</Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography fontWeight={600}>Status</Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography fontWeight={600}>Amount</Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography fontWeight={600}>Date</Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography fontWeight={600}>Action</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.map((row) => (
                <OperationHistoryRowComponent
                  key={`history-${row?.createdAt}`}
                  data={row}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
