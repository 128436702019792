import { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
  Box,
  Typography,
  Checkbox,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { TwoFASettings } from '../../types/user.profile.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, number, boolean } from 'yup';
import 'react-phone-input-2/lib/material.css';
import Paper from '@mui/material/Paper';
import { useNotification, useServerError } from 'hooks';
import { useGetUserQuery, useProfileUpdateMutation } from 'services';
import { TwoFaModalComponent } from 'shared';

type SettingsProps = {};
export const Settings: FC<SettingsProps> = () => {
  const { showNotification } = useNotification();
  const {
    data: userData,
    error: userDataError,
    isError: userDataIsError,
    refetch: refetchUserData,
  } = useGetUserQuery();
  const [
    updateInfo,
    {
      isLoading: isLoadingUpdateUser,
      isError: isErrorUpdateUser,
      error: errorUpdateUser,
      isSuccess: isSuccessUpdateUser,
    },
  ] = useProfileUpdateMutation();

  const { handleSubmit, reset, control } = useForm<TwoFASettings>({
    defaultValues: {
      send2FA: false,
      withdraw2FA: false,
      login2FA: false,
      changeSettings2FA: true,
      sendLimit: 0,
      withdrawLimit: 0,
    },
    resolver: yupResolver(
      object({
        changeSettings2FA: boolean().required(
          'Please select 2FA for Change Settings'
        ),
        send2FA: boolean().required('Please select 2FA for Send'),
        withdraw2FA: boolean().required('Please select 2FA for Withdraw'),
        login2FA: boolean().required('Please select 2FA for Login'),
        sendLimit: number()
          .required('Please enter the Send Limit')
          .min(0, 'Send Limit must be a positive number'),
        withdrawLimit: number()
          .required('Please enter the Withdraw Limit')
          .min(0, 'Withdraw Limit must be a positive number'),
      })
    ),
  });

  const onSubmit = (data: TwoFASettings, code: string) => {
    const {
      withdrawLimit,
      withdraw2FA,
      send2FA,
      sendLimit,
      login2FA,
      changeSettings2FA,
    } = data;

    updateInfo({
      isWithdraw2faEnabled: withdraw2FA,
      isSendCrypto2faEnabled: send2FA,
      isLogin2faEnabled: login2FA,
      withdrawLimit,
      sendCryptoLimit: sendLimit,
      isChangeSettings2faEnabled: changeSettings2FA,
      code,
    });
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;
    handleSubmit(async (data) => await onSubmit(data, code))();
  };

  useServerError({ isError: isErrorUpdateUser, error: errorUpdateUser });
  useServerError({ isError: userDataIsError, error: userDataError });

  useEffect(() => {
    if (userData) {
      reset({
        send2FA: userData?.isSendCrypto2faEnabled,
        withdraw2FA: userData?.isWithdraw2faEnabled,
        login2FA: userData?.isLogin2faEnabled,
        sendLimit: userData?.sendCryptoLimit,
        withdrawLimit: userData?.withdrawLimit,
        changeSettings2FA: userData?.isChangeSettings2faEnabled,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (isSuccessUpdateUser) {
      refetchUserData();
      showNotification('User 2FA settings was updated!', 'success');
    }
  }, [isSuccessUpdateUser]);

  return (
    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 } }}>
      <Typography component="h1" variant="h4" align="center">
        Settings
      </Typography>
      <Typography variant="h6" gutterBottom>
        2FA and limits settings
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Grid item xs={12}>
            <Controller
              control={control}
              name="changeSettings2FA"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="2 Factor Authorization for Change Settings"
                  />
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="login2FA"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="2 Factor Authorization on Login"
                  />
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="send2FA"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="2 Factor Authorization on Send Operation"
                  />
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Controller
              control={control}
              name="sendLimit"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  margin="normal"
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="sendLimit"
                  label="Send Amount Limit"
                  name="sendLimit"
                  type="number"
                  size="small"
                  disabled={isLoadingUpdateUser}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="withdraw2FA"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl error={!!error}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="2 Factor Authorization on Withdraw Operation"
                  />
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Controller
              control={control}
              name="withdrawLimit"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  margin="normal"
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="withdrawLimit"
                  label="Withdraw Amount Limit"
                  name="withdrawLimit"
                  type="number"
                  size="small"
                  disabled={isLoadingUpdateUser}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TwoFaModalComponent
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          type="submit"
          onComplete={confirm2FaHandle}
        >
          Update
        </TwoFaModalComponent>
      </Box>
    </Paper>
  );
};
