import { IUser } from 'types/user.interface';
import {
  PATH_ACCOUNT_STATEMENT_API,
  PATH_USER_API,
  PATH_USER_PDF_API,
} from '../constants/api-routes';
import {
  AccountStatementQueryParams,
  IAccountStatementsFilters,
  IbanUpdate,
  IUserBalanceHistory,
  UserFormInterface,
  UserPasswordRecoveryInterface,
} from 'types/user.profile.interface';
import { baseApi } from './base.api';

export const userService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    profileUpdate: build.mutation<IUser, UserFormInterface>({
      query: (params) => ({
        url: `${PATH_USER_API.PROFILE}`,
        method: 'PUT',
        body: params,
      }),
    }),
    passwordUpdate: build.mutation<IUser, UserPasswordRecoveryInterface>({
      query: (params) => ({
        url: `${PATH_USER_API.PASSWORD}`,
        method: 'PUT',
        body: params,
      }),
    }),
    ibanUpdate: build.mutation<IbanUpdate, IbanUpdate>({
      query: (params) => ({
        url: `${PATH_USER_API.IBAN}`,
        method: 'POST',
        body: params,
      }),
    }),
    ibanDelete: build.mutation<IbanUpdate, { id: number }>({
      query: ({ id }) => ({
        url: `${PATH_USER_API.IBAN}/${id}`,
        method: 'DELETE',
      }),
    }),
    change2fa: build.mutation<any, { type: string }>({
      query: (body) => ({
        url: PATH_USER_API.CHANGE_2FA,
        method: 'PUT',
        body,
      }),
    }),
    delete2FaTotp: build.mutation<any, {}>({
      query: () => ({
        url: PATH_USER_API.DELETE_2FA_TOTP,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    requestToPdfAccountConfirmation: build.mutation<any, {}>({
      query: () => ({
        url: PATH_USER_PDF_API.ACCOUNT_CONFIRMATION,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    getPdfAccountConfirmation: build.query<any, { userName: string }>({
      query: ({ userName }) => ({
        url: PATH_USER_PDF_API.ACCOUNT_CONFIRMATION,
        headers: {
          Accept: 'application/octet-stream',
        },
        responseHandler: (response: any) =>
          response
            .blob()
            .then((blob: any) => URL.createObjectURL(blob))
            .then((url: any) => {
              const link = document.createElement('a');
              link.href = url;
              link.download = `Account-confirmation-${userName}`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }),
      }),
    }),
    getPdfAccountStatement: build.query<any, AccountStatementQueryParams>({
      query: ({ userName = '', ...rest }) => {
        const params: AccountStatementQueryParams = {};
        Object.keys(rest).forEach((key) => {
          if (rest[key as keyof typeof rest]) {
            params[key as keyof AccountStatementQueryParams] =
              rest[key as keyof typeof rest];
          }
        });

        return {
          url: PATH_USER_PDF_API.ACCOUNT_STATEMENT,
          params,
          headers: {
            Accept: 'application/octet-stream',
          },
          responseHandler: (response: any) =>
            response
              .blob()
              .then((blob: any) => URL.createObjectURL(blob))
              .then((url: any) => {
                const link = document.createElement('a');
                link.href = url;
                link.download = `Account-statement-${userName}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }),
        };
      },
    }),
    getAccountFilters: build.query<IAccountStatementsFilters, {}>({
      query: () => ({
        url: PATH_ACCOUNT_STATEMENT_API.ACCOUNT_STATEMENT_FILTERS,
      }),
    }),
    getBalanceHistory: build.query<
      IUserBalanceHistory[],
      { lastDays: string | number }
    >({
      query: (params) => ({
        url: PATH_USER_API.BALANCE_HISTORY,
        params,
      }),
    }),
    uploadAvatar: build.mutation<
      { message: string; profileImage: string },
      FormData
    >({
      query: (body) => ({
        url: PATH_USER_API.UPLOAD_AVATAR,
        method: 'PUT',
        body,
        formData: true,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useProfileUpdateMutation,
  usePasswordUpdateMutation,
  useIbanUpdateMutation,
  useChange2faMutation,
  useIbanDeleteMutation,
  useDelete2FaTotpMutation,
  useRequestToPdfAccountConfirmationMutation,
  useGetPdfAccountConfirmationQuery,
  useGetAccountFiltersQuery,
  useGetPdfAccountStatementQuery,
  useGetBalanceHistoryQuery,
  useUploadAvatarMutation,
} = userService;
