import { Box, SxProps, Theme } from '@mui/material';
import React, { FC, useState } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

interface DailyFilterHookResult {
  DailyFilterButtons: FC;
  period: string;
}

interface IDailyFilter {
  days?: string[] | number[];
}

interface IButtonsProps {
  wrapperSx?: SxProps<Theme>;
  buttonGroupSx?: SxProps<Theme>;
  toggleButtonSx?: SxProps<Theme>;
}

export const useDailyFilter = ({
  days = ['7', '30'],
}: IDailyFilter): DailyFilterHookResult => {
  const [period, setPeriod] = useState<string>(days[0].toString());

  const handleChangePeriod = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setPeriod(newAlignment);
  };

  const DailyFilterButtons: FC<IButtonsProps> = ({
    wrapperSx,
    buttonGroupSx,
    toggleButtonSx,
  }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '10px',
          ...wrapperSx,
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={period}
          exclusive
          onChange={handleChangePeriod}
          aria-label="Platform"
          size="small"
          sx={{ border: 'none', gap: '20px', ...buttonGroupSx }}
        >
          {days.map((day) => (
            <ToggleButton
              key={day}
              sx={{
                border: 'none',
                borderTopRightRadius: '4px !important',
                borderBottomRightRadius: '4px !important',
                borderTopLeftRadius: '4px !important',
                borderBottomLeftRadius: '4px !important',
                ...toggleButtonSx,
              }}
              value={day.toString()}
            >
              {`${day} days`}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    );
  };

  return { DailyFilterButtons, period };
};
