import { Paper, Typography, styled, Box } from '@mui/material';
import React, { FC, SyntheticEvent, useState } from 'react';
import { theme } from '../../../assets/theme';
import { ExchangeForm, WalletForm } from '../../../components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { WithdrawCryptoForm } from '../../../components';
import { useB2C2RatesContext } from 'context/B2C2RatesContext';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TABS = ['Receive', 'Withdraw', 'Convert'];

export const Wallets: FC = () => {
  const [value, setValue] = useState(0);
  const prices = useB2C2RatesContext();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Section
      elevation={4}
      sx={{
        mt: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Typography
          sx={{ typography: { md: 'h5', xs: 'h6' } }}
          color="inherit"
          noWrap
          align="left"
          whiteSpace={'pre-wrap'}
        >
          Your wallet
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {TABS.map((tab, index) => {
              return (
                <Tab
                  sx={{
                    transition: '0.3s',
                    height: '40px',
                    minHeight: '40px',
                    textTransform: 'capitalize',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    background:
                      value === index
                        ? theme.palette.secondary.main
                        : 'inherit',
                  }}
                  key={tab}
                  label={tab}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <WalletForm />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <WithdrawCryptoForm sendBtnFullWidth />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ExchangeForm
            loadingSx={{ top: 0, left: 0 }}
            prices={prices}
            exchangeBtnFullWidth
          />
        </CustomTabPanel>
      </Box>
    </Section>
  );
};
