import React, { FC } from 'react';
import { Button, SxProps, Theme } from '@mui/material';
import { setModalState } from '../../store/ui';
import { useDispatch } from 'react-redux';

interface AccountStatementBtnProps {
  variant?: 'outlined' | 'text' | 'contained' | undefined;
  title?: string;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
}

export const AccountStatementBtn: FC<AccountStatementBtnProps> = ({
  variant = 'outlined',
  title = 'Open Filter modal',
  sx,
  fullWidth = false,
}) => {
  const dispatch = useDispatch();

  const accountStatementModalOpen = () =>
    dispatch(setModalState({ visible: true, name: 'accountStatement' }));

  return (
    <Button
      fullWidth={fullWidth}
      sx={sx}
      variant={variant}
      onClick={accountStatementModalOpen}
    >
      {title}
    </Button>
  );
};
