const ROOTS_AUTH = '/auth';

export enum StepsRegisterEnum {
  VERIFY_EMAIL = 'verify-email',
  VERIFY_PHONE = 'verify-phone',
}

export const PATH_REGISTER = {
  ROOT: ROOTS_AUTH,
  LOGIN: `${ROOTS_AUTH}/login`,
  REGISTER: `${ROOTS_AUTH}/register`,
  REGISTER_PHONE_VERIFY: `${ROOTS_AUTH}/register?${StepsRegisterEnum.VERIFY_PHONE}`,
  REGISTER_EMAIL_VERIFY: `${ROOTS_AUTH}/register?${StepsRegisterEnum.VERIFY_EMAIL}`,
  FORGOT_PASSWORD: `${ROOTS_AUTH}/forgot-password`,
  LOGIN_VERIFY: `${ROOTS_AUTH}/login-verify`,
  LOGIN_PHONE_VERIFY: `${ROOTS_AUTH}/login-phone-verify`,
  GOOGLE_VERIFY: `${ROOTS_AUTH}/google-verify`,
};

export const PAGES_PATH = {
  ROOT: '',
  TRANSACTION_HISTORY: `/transaction-history`,
};
