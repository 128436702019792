import { Paper, Typography, styled, Box, Button } from '@mui/material';
import React, { FC } from 'react';
import { theme } from '../../../../assets/theme';
import { AccountStatementBtn } from '../../../../components';
import { AccountStatementTable } from './account.statement.table';
import { useGetTransactionsQuery } from '../../../../services';
import { useNavigate } from 'react-router-dom';
import { PAGES_PATH } from '../../../../constants/spa-routes';
import { useDailyFilter, useServerError } from '../../../../hooks';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

export const AccountStatementsBlock: FC = () => {
  const { DailyFilterButtons, period } = useDailyFilter({});

  const {
    data: transactionsData,
    isError: transactionsIsError,
    error: transactionsError,
    isFetching: transactionsIsFetching,
  } = useGetTransactionsQuery({
    lastDays: period,
  });

  const navigate = useNavigate();

  const sortedData =
    transactionsData &&
    [...transactionsData]?.sort((a, b) => {
      const dateA = new Date(a?.createdAt);
      const dateB = new Date(b?.createdAt);
      return dateB?.getTime() - dateA?.getTime();
    });

  useServerError({ isError: transactionsIsError, error: transactionsError });

  const seeAllHandler = () => {
    navigate(PAGES_PATH.TRANSACTION_HISTORY);
  };

  return (
    <Section
      elevation={4}
      sx={{
        mt: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        flexWrap: { sm: 'nowrap', xs: 'wrap' },
        justifyContent: { xs: 'center' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          sx={{ typography: { md: 'h5', xs: 'h6' } }}
          color="inherit"
          noWrap
          align="left"
          whiteSpace={'pre-wrap'}
        >
          Account statement
        </Typography>
        <Button onClick={seeAllHandler}>See all</Button>
      </Box>
      <DailyFilterButtons />
      <AccountStatementTable
        transactionsData={sortedData}
        transactionsIsFetching={transactionsIsFetching}
      />
      <AccountStatementBtn
        variant={'contained'}
        title="Download PDF"
        fullWidth
      />
    </Section>
  );
};
