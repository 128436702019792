import React, { FC, memo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { theme } from '../../../assets/theme';
import { IUserBalanceHistory } from '../../../types/user.profile.interface';
import { transformDate } from '../../../helpers/dateTransformHelper';
import { DateFormatEnum } from '../../../types/dateTimeFormatOption';
import { formatPrice } from '../../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  tension: 0.3,
  plugins: {
    legend: {
      display: false,
    },
  },
};

interface EstimatedBalanceChartProps {
  balanceHistoryData: IUserBalanceHistory[] | undefined;
}

export const EstimatedBalanceChart: FC<EstimatedBalanceChartProps> = memo(
  ({ balanceHistoryData }) => {
    const dateToday = transformDate(new Date(), DateFormatEnum.YEAR_MONTH_DAY);
    const labels = balanceHistoryData?.map((el) =>
      transformDate(el.date as string, DateFormatEnum.YEAR_MONTH_DAY)
    );

    const emptyBalanceHistory = [0];

    const isItems = !!balanceHistoryData?.length;

    const labelsDisplay = isItems ? labels : [dateToday];
    const dataDisplay = isItems
      ? labels?.map(
          (el, index) =>
            balanceHistoryData &&
            formatPrice(balanceHistoryData[index]?.balance)
        )
      : emptyBalanceHistory;

    const data = {
      labels: labelsDisplay,
      datasets: [
        {
          fill: true,
          label: 'Balance',
          data: dataDisplay,
          borderColor: theme.palette.secondary.main,
          backgroundColor: 'rgba(235,171,53,0.2)',
        },
      ],
    };

    return <Line options={options} data={data} />;
  }
);
