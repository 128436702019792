import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { CurrencyExchange, Payment, Send } from '@mui/icons-material';
import { useBalance } from 'hooks';
import { Fiat } from 'store/wallet/types';
import { useAppSelector } from 'store';
import { useB2C2RatesContext } from 'context/B2C2RatesContext';
import { formatPrice } from 'utils';
import { CryptoInstruments } from 'pages/exchange/exchange.page';

const OrderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
}));

type FiatOrderBlockProps = {
  onSend: () => void;
  coinOptions: any;
};

const columnStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const FiatOrderBlock: FC<FiatOrderBlockProps> = ({
  onSend,
  coinOptions,
}) => {
  const { balance } = useBalance();
  const { selectedCurrency } = useAppSelector((s) => s.uiReducer);
  const accountBalance: number =
    balance?.withdraw?.[coinOptions?.symbol as Fiat]?.total || 0;
  const rates = useB2C2RatesContext();

  const currencyRate = useMemo(() => {
    if (coinOptions?.symbol === selectedCurrency.symbol) {
      return 1;
    }
    const rate =
      rates[
        `${selectedCurrency.symbol}${coinOptions?.symbol}` as CryptoInstruments
      ];
    return rate ? rate.price : 0;
  }, [selectedCurrency.symbol, rates]);

  return (
    <OrderWrapper sx={{ flexDirection: { lg: 'row', xs: 'column' } }}>
      <Grid
        container
        alignItems="center"
        sx={{
          width: { lg: '70%', xs: '100%' },
          gap: { md: '0', lg: '0', xs: '20px' },
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          minWidth={150}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }} mr={1}>
            <Box width={30} height={30} mr={1}>
              {coinOptions.icon}
            </Box>
            <Typography mr={1} fontWeight="bold">
              {coinOptions.symbol}
            </Typography>
            <Typography variant="body2" component="p">
              {coinOptions.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            1 {selectedCurrency.symbol} = {formatPrice(currencyRate)}{' '}
            {coinOptions.sign}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}
        >
          <Box sx={columnStyles}>
            <Typography textAlign="left">Balance</Typography>
            <Typography mt={1}>
              {Number(accountBalance).toFixed(4)} {coinOptions.sign}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: { lg: '30%', xs: '100%' },
          gap: { lg: '30px', xs: '20px' },
        }}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            disabled
            variant="text"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Payment />
            <Typography>Receive</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={onSend}
            variant="text"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 90,
            }}
          >
            <Send />
            <Typography>Withdraw</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled
            variant="text"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CurrencyExchange />
            <Typography>Convert</Typography>
          </Button>
        </Grid>
      </Grid>
    </OrderWrapper>
  );
};
