// created by Artem
import { FC, useEffect } from 'react';
import { Navigate, Outlet, Link } from 'react-router-dom';
import {
  Container,
  Link as MaterialLink,
  styled,
  Typography,
} from '@mui/material';
import { AuthWrapComponent, LoadingComponent } from 'shared';
import { useGetUserQuery } from 'services';
import Logo from 'assets/img/QBS-fg.svg';
import { useServerError } from '../hooks';

const StyledLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: 300,
  top: 0,
  left: 0,
  [theme.breakpoints.down('md')]: { width: 200 },
}));

export const AuthLayout: FC = () => {
  const storedToken = localStorage.getItem('token');
  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useGetUserQuery(undefined, {
    skip: !storedToken,
  });

  useServerError({ isError, error });

  useEffect(() => {
    if (isError) {
      localStorage.removeItem('token');
    }
  }, [isError]);

  if (isLoading) return <LoadingComponent fullscreen={true} />;
  if (user) return <Navigate to="/" />;

  return (
    <AuthWrapComponent>
      <Link to={'/'}>
        <StyledLogo src={Logo} alt="logo" />
      </Link>
      <Container component="main" maxWidth="xs">
        <Outlet />
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          <MaterialLink
            color="inherit"
            target="_blank"
            href="https://wetelo.com/"
          >
            QBS
          </MaterialLink>
          {` ${new Date().getFullYear()}.`}
        </Typography>
      </Container>
    </AuthWrapComponent>
  );
};
