// created by Artem
import { Container, styled } from '@mui/material';
import { Footer, Header } from 'components';
import { FC, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetUserQuery } from 'services';
import { LoadingComponent } from 'shared';
import { ModalManager } from 'components/modals/modal_manager';
import { RatesProvider } from 'context/B2C2RatesContext';
import { useServerError } from '../hooks';

const Wrapper = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled(Container)({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
});

type AppLayoutProps = {};
export const AppLayout: FC<AppLayoutProps> = () => {
  const storedToken = localStorage.getItem('token');
  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useGetUserQuery(undefined, {
    skip: !storedToken,
  });

  useServerError({ isError, error });

  useEffect(() => {
    if (isError) {
      localStorage.removeItem('token');
    }
  }, [isError]);

  if (isLoading) return <LoadingComponent fullscreen={true} />;
  if (!user) return <Navigate to="/auth" />;

  return (
    <RatesProvider>
      <Wrapper>
        <Header />
        <Content maxWidth="xl">
          <Outlet />
        </Content>
        <ModalManager />
        <Footer />
      </Wrapper>
    </RatesProvider>
  );
};
