import { theme } from '../assets/theme';
import { WithdrawStatusEnum } from '../types/withdraw.interface';

const getColorByStatusHelper = (status: string | boolean) => {
  if (typeof status == 'boolean') {
    if (status) {
      return theme.palette.success.main;
    } else {
      return theme.palette.error.main;
    }
  } else {
    if (status === WithdrawStatusEnum.PENDING)
      return theme.palette.warning.main;
    if (status === WithdrawStatusEnum.COMPLETED)
      return theme.palette.success.main;
    if (status === WithdrawStatusEnum.APPROVED)
      return theme.palette.success.main;
    if (status === WithdrawStatusEnum.CANCELED) return theme.palette.error.main;
  }
  return 'inherit';
};

export { getColorByStatusHelper };
