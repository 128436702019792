// created by Artem
import { FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ForgotFormValues } from 'types/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { emailScheme } from 'utils';
import { useNotification, useServerError } from 'hooks';
import { useForgotPassMutation } from 'services';

type ForgotPageProps = {};
export const ForgotPage: FC<ForgotPageProps> = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [forgot, { isError, error }] = useForgotPassMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotFormValues>({
    defaultValues: { email: '' },
    resolver: yupResolver(object({ email: emailScheme({ required: true }) })),
  });

  const doneHandle = () => {
    showNotification('The recovery link has been sent to your email.');
    navigate('/auth');
  };

  const onSubmit: SubmitHandler<ForgotFormValues> = (data) =>
    forgot(data)
      .unwrap()
      .then(doneHandle)
      .catch(() => null);

  useServerError({ isError, error });

  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          {...register('email')}
          error={!!errors?.email?.message}
          helperText={errors?.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit
        </Button>
        <Link variant="body2" component={NavLink} to="/auth">
          Back
        </Link>
      </Box>
    </Box>
  );
};
