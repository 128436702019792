import {
  Divider,
  Paper,
  Stack,
  Typography,
  styled,
  Box,
  Button,
} from '@mui/material';
import { FC } from 'react';
import { ExchangeModal } from 'components/modals/exchange';
import { EXCHANGE_COIN_LIST } from 'shared/currencies/currencies';
import { CryptoOrderBlock } from 'components/crypto_order_block/crypto_order_block';
import { Container } from 'shared/container/container';
import { Crypto, Fiat } from 'store/wallet/types';
import { useAppDispatch, useAppSelector } from 'store';
import { ArrowRight } from '@mui/icons-material';
import KYCLogo from 'assets/kyc-logo.png';
import { useBalance, useCheckKYC, useNotification } from 'hooks';
import { setModalState } from 'store/ui';
import { useGetUserQuery } from 'services';
import { Link } from 'react-router-dom';
import { FiatOrderBlock } from 'components/fiat_order_block/fiat_order_block';
import { FIAT_CURRENCY_LIST } from '../../shared/currencies/currencies';
import { formatPrice } from '../../utils';
import { CurrenciesSelector } from '../../shared/currencies/currencies.selector';
import { usePricesAndCrypto } from '../../hooks/usePricesAndCrypto';
import { useB2C2RatesContext } from 'context/B2C2RatesContext';
import { getNormalizedCrypto } from 'helpers/normalizeCryptoNames';

type CryptocurrencyInfo = {
  price: string;
};

type B2C2CryptoNames = Exclude<Crypto, 'USDT' | 'USDC'> | 'USC' | 'UST';

type MapCryptoInstruments<T extends Fiat> = {
  [P in B2C2CryptoNames as `${P}${T}`]: T;
};

export type CryptoInstruments = keyof MapCryptoInstruments<Fiat>;

export type Cryptocurrencies = Partial<
  Record<CryptoInstruments, CryptocurrencyInfo>
>;

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`;

export const ExchangePage: FC = () => {
  const { check: checkKYC, isVerified } = useCheckKYC();
  const { modalsVisibility, selectedCurrency } = useAppSelector(
    (s) => s.uiReducer
  );
  const { total } = useBalance();
  const dispatch = useAppDispatch();
  const { data: userData } = useGetUserQuery();
  const { showNotification } = useNotification();
  const prices = useB2C2RatesContext();
  const { setSelectedCrypto, selectedCrypto } = usePricesAndCrypto();

  const onExchangeOrderClick = (symbol: Crypto) => {
    if (!checkKYC()) return;

    dispatch(setModalState({ visible: true, name: 'exchange' }));
    setSelectedCrypto(symbol);
  };

  const onDepositOrderClick = (symbol: Crypto) => {
    if (!checkKYC()) return;

    dispatch(setModalState({ visible: true, name: 'deposit', symbol }));
  };

  const onSendCrypto = (symbol: Crypto) => {
    if (!checkKYC()) return;

    dispatch(setModalState({ visible: true, name: 'sendCrypto', symbol }));
  };

  const onSendFiat = () => {
    if (!checkKYC()) return;

    if (userData?.Ibans?.length === 0) {
      showNotification('Please add IBAN address in user profile', 'warning');
      return;
    }

    dispatch(setModalState({ visible: true, name: 'sendFiat' }));
  };

  const onModalClose = () => {
    dispatch(setModalState({ visible: false, name: 'exchange' }));
    setSelectedCrypto('');
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexDirection: { sm: 'row', xs: 'column' },
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h4" color="inherit">
          Exchange
        </Typography>
        <Section
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: '20px',
            flexWrap: 'wrap',
            width: { sm: 'auto', xs: '100%' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100%',
              flexDirection: 'column',
              gap: '20px',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: '1.3em', xs: '1.2em' },
              }}
              variant="h5"
              color="inherit"
              align="left"
            >
              Total Assets
            </Typography>
            <Box
              sx={{
                width: { sm: 'auto', xs: '100%' },
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                variant="h5"
                color="inherit"
                noWrap
                align="left"
                sx={{
                  fontSize: { sm: '1.3em', xs: '1.2em' },
                }}
              >
                {formatPrice(total * selectedCurrency.rate, 2)}{' '}
              </Typography>
              <CurrenciesSelector />
            </Box>
          </Box>
        </Section>
      </Box>
      {!isVerified ? (
        <Section
          elevation={4}
          sx={{
            mt: 4,
            display: 'flex',
            alignItems: 'flex-start',
            gap: '10px',
            flexWrap: { sm: 'nowrap', xs: 'wrap' },
            justifyContent: { xs: 'center' },
          }}
        >
          <img src={KYCLogo} width="100" height="100" alt="kyc" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            ml={2}
          >
            <Typography
              sx={{ typography: { md: 'h6', xs: 'h6' } }}
              color="inherit"
              noWrap
              align="left"
              whiteSpace={'pre-wrap'}
            >
              Currently all operations are disabled. <br />
              To make them available you need to start or finish KYC
              verification process.
            </Typography>
            <Button variant="contained" sx={{ mt: 1 }}>
              <NavLink to="/user-profile/kyc">
                <Typography>Start verification</Typography>
                <ArrowRight />
              </NavLink>
            </Button>
          </Box>
        </Section>
      ) : null}
      <Section sx={{ mt: 4 }} elevation={4}>
        <Typography variant="h5" color="inherit" noWrap align="left">
          Fiat Assets
        </Typography>
        <Stack spacing={1} width="100%">
          {FIAT_CURRENCY_LIST.map((ec, idx) => {
            return (
              <Box key={idx}>
                <FiatOrderBlock
                  onSend={() => onSendFiat()}
                  coinOptions={{
                    ...ec,
                  }}
                />
                {idx !== FIAT_CURRENCY_LIST.length - 1 ? <Divider /> : null}
              </Box>
            );
          })}
        </Stack>
      </Section>
      <Section sx={{ mt: 4 }} elevation={4}>
        <Typography variant="h5" color="inherit" noWrap align="left">
          Crypto Assets
        </Typography>
        <Stack spacing={1} width="100%">
          {EXCHANGE_COIN_LIST.map((ec, idx) => {
            const key = getNormalizedCrypto(
              ec.symbol,
              selectedCurrency.symbol
            ) as keyof typeof prices;
            const { price } = prices[key] || {
              price: '0',
            };
            return (
              <Box key={ec.id + idx}>
                <CryptoOrderBlock
                  onSend={() => onSendCrypto(ec.symbol)}
                  onExchange={() => onExchangeOrderClick(ec.symbol)}
                  onDeposit={() => onDepositOrderClick(ec.symbol)}
                  coinOptions={{
                    ...ec,
                    price,
                  }}
                />
                {idx !== EXCHANGE_COIN_LIST.length - 1 ? <Divider /> : null}
              </Box>
            );
          })}
        </Stack>
      </Section>

      {modalsVisibility.exchange && (
        <ExchangeModal
          isOpen={modalsVisibility.exchange}
          prices={prices}
          symbol={selectedCrypto as Crypto}
          onClose={onModalClose}
        />
      )}
    </Container>
  );
};
