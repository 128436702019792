import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { getColorByStatusHelper } from '../../../../helpers/getColorByStatusHelper';
import {
  IDeposits,
  IExchanges,
  IWithdrawals,
} from '../../../../types/operation-history.interface';
import { NoItemsWrapper } from '../../../../shared';
import { formatPrice } from '../../../../utils';

interface AccountStatementTableProps {
  transactionsData: (IExchanges | IDeposits | IWithdrawals)[] | undefined;
  transactionsIsFetching: boolean;
}

export const AccountStatementTable: React.FC<AccountStatementTableProps> = ({
  transactionsData,
  transactionsIsFetching,
}) => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table sx={{ minWidth: 100 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>ID</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>Details</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>Status</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>Fiat Amount</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>Asset Amount</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <NoItemsWrapper
            length={transactionsData?.length as number}
            isLoading={transactionsIsFetching}
            isTableComponent
            colSpan={5}
          >
            {transactionsData?.slice(0, 100).map((row) => {
              return (
                <TableRow
                  key={`${row.id}${row.createdAt}`}
                  sx={{ 'td, th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography>#{row.id}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{row.type}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={getColorByStatusHelper(row.status)}>
                      {row.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color={getColorByStatusHelper(!!row.fiatAmount)}
                    >
                      {formatPrice(row?.fiatAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color={getColorByStatusHelper(!!row.assetAmount)}
                    >
                      {formatPrice(row?.assetAmount)}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </NoItemsWrapper>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
