import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { Cryptocurrencies } from 'pages/exchange/exchange.page';
import {
  INIT_B2C2,
  GET_B2C2_RATES,
  getSocketClient,
} from 'services/socket.service';
import { calculateAdditionalPairs } from 'helpers/getAdditionalFiatPairs';

const socket = getSocketClient();
const RatesContext = createContext<Cryptocurrencies>({});

export const useB2C2RatesContext = () => useContext(RatesContext);

export const RatesProvider = ({ children }: { children: ReactNode }) => {
  const [rates, setRates] = useState<Cryptocurrencies>({});

  useEffect(() => {
    const isInitialized = JSON.parse(localStorage.getItem(INIT_B2C2) || '');

    if (!isInitialized) {
      socket.emit(INIT_B2C2);
      localStorage.setItem(INIT_B2C2, JSON.stringify(true));
    }

    socket.on(GET_B2C2_RATES, (msg: any) => {
      if (msg.event !== 'price') {
        return;
      }

      if (msg.levels) {
        const key = msg.instrument.replace('.SPOT', '');

        if (msg.levels.sell) {
          setRates((prev) => ({
            ...prev,
            [key]: { price: msg.levels.sell[0].price },
          }));
        }
      }
    });

    return () => {
      socket.off(GET_B2C2_RATES);
    };
  }, []);

  return (
    <RatesContext.Provider value={calculateAdditionalPairs(rates)}>
      {children}
    </RatesContext.Provider>
  );
};
