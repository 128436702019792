import { FC, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Dialog, Button, TextareaAutosize } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';
import { ReviewInterface } from '../../../types/review.interface';
import { theme } from '../../../assets/theme';
import CardHeader from '@mui/material/CardHeader';
import {
  useCreateReviewMutation,
  useProfileUpdateMutation,
} from '../../../services';
import { useNotification, useServerError } from '../../../hooks';

type ReviewModalProps = {
  open: boolean;
  onClose: () => void;
};

const ADMIN_ID = Number(process.env.REACT_APP_ADMIN_ID) || 13;

export const ReviewLeftModal: FC<ReviewModalProps> = ({ open, onClose }) => {
  const { showNotification } = useNotification();
  const [
    createReview,
    { isError: isErrorCreateReview, error: errorCreateReview, isSuccess },
  ] = useCreateReviewMutation();
  const [updateProfile] = useProfileUpdateMutation();
  const { handleSubmit, control, reset } = useForm<ReviewInterface>({
    defaultValues: {
      rating: 1,
      comments: '',
    },
    resolver: yupResolver(
      object({
        rating: number().required(),
        comments: string().max(700),
      })
    ),
  });

  const onSubmit: SubmitHandler<ReviewInterface> = (data) => {
    createReview({
      comment: data.comments,
      rating: data.rating,
      reviewee: ADMIN_ID,
    });
    updateProfile({ hasLeaveFeedback: true });
    onClose();
  };

  const onModalClose = () => {
    onClose();
  };

  useServerError({ isError: isErrorCreateReview, error: errorCreateReview });

  useEffect(() => {
    reset();
  }, [open]);

  useEffect(() => {
    if (isSuccess) {
      showNotification('Feedback has been successfully left', 'success');
    } else {
      onClose();
    }
  }, [isSuccess]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Card sx={{ minWidth: 275 }}>
        <CardHeader title="Leave your feedback" />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Rating
            </Typography>
            <Controller
              control={control}
              name="rating"
              render={({ field: { onChange, value } }) => (
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Comments
            </Typography>
            <Controller
              control={control}
              name="comments"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={10}
                    value={value}
                    maxRows={30}
                    onChange={onChange}
                    placeholder="Comments"
                    style={{ width: '100%', padding: '10px' }}
                  />
                  {!!error?.message && (
                    <Typography
                      align="left"
                      variant="caption"
                      color={theme.palette.error.main}
                      sx={{ margin: '4px 14px 0 14px', width: '100%' }}
                    >
                      {error?.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', padding: '16px' }}>
            <Button
              onClick={onModalClose}
              type="submit"
              variant="contained"
              size="small"
            >
              Close
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              type="submit"
              variant="contained"
              size="small"
            >
              Leave a review
            </Button>
          </CardActions>
        </Box>
      </Card>
    </Dialog>
  );
};
