import { useEffect, useState } from 'react';
import { useGetNotificationsQuery } from 'services';
import { Notification } from 'types/wallet.interface';
import { useNotification } from './useNotification';
import { getSocketClient, userToken } from 'services/socket.service';

const client = getSocketClient();

const GET_NOTIFICATIONS_EVENT_LISTENER = 'notification-sent';
const READ_NOTIFICATIONS_EVENT_LISTENER = 'notification-read';

export const useWSNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { showNotification } = useNotification();
  const [isInitialized, setInitialisationStatus] = useState(false);
  const { data: fetchedNotifications, isLoading } = useGetNotificationsQuery(
    {},
    { skip: !userToken }
  );

  useEffect(() => {
    if (!client.connected) {
      client.connect();
      setInitialisationStatus(true);
    }

    return () => {
      client.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setNotifications(() => fetchedNotifications as Notification[]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      client.on(
        GET_NOTIFICATIONS_EVENT_LISTENER,
        (notification: Notification) => {
          showNotification('You have new unread notification.', 'info');
          setNotifications((prevNotifications) => [
            ...prevNotifications,
            notification,
          ]);
        }
      );
    }

    return () => {
      client.off(GET_NOTIFICATIONS_EVENT_LISTENER);
    };
  }, []);

  const onReadHandler = (notification: Notification) => {
    const { id } = notification;
    client.emit(READ_NOTIFICATIONS_EVENT_LISTENER, { ids: [id] });
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.id !== id)
    );
  };

  const hasUnreadNotifications =
    (notifications || []).filter((notification) => !notification.read).length >
    0;

  return {
    notifications,
    hasUnreadNotifications,
    onReadHandler,
  };
};
