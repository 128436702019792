import { Socket, io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';
export const userToken = localStorage.getItem('token');
let socket: Socket | null = null;

export const INIT_B2C2 = 'b2c2-init';
export const GET_B2C2_RATES = 'b2c2-data';

export const getSocketClient = (): Socket => {
  if (!socket) {
    socket = io(SOCKET_URL, {
      auth: { token: userToken },
      reconnectionDelay: 10000,
    });
    socket.removeAllListeners();
    localStorage.setItem(INIT_B2C2, JSON.stringify(false));
  }
  return socket;
};
