import { useState } from 'react';
import { Crypto } from '../store/wallet/types';

export const usePricesAndCrypto = () => {
  const [selectedCrypto, setSelectedCrypto] = useState<Crypto | ''>('');

  return {
    selectedCrypto: selectedCrypto as Crypto,
    setSelectedCrypto,
  };
};
