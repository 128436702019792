import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useGetUserQuery } from '../../../services';
import { Box, Button } from '@mui/material';
import { setModalState } from '../../../store/ui';
import { useDispatch } from 'react-redux';
import GoogleIcon from '@mui/icons-material/Google';
import LoginIcon from '@mui/icons-material/Login';
import { VerifiedDetails } from './verified.details';
import { AccountConfirmationBtn } from '../../../components';

interface SidebarProps {
  title: string;
}

export default function Sidebar(props: SidebarProps) {
  const { title } = props;

  const { data: userData } = useGetUserQuery();

  const dispatch = useDispatch();

  const googleLoginClick = () =>
    dispatch(setModalState({ visible: true, name: 'googleLogin' }));

  const loginTypeClick = () =>
    dispatch(setModalState({ visible: true, name: 'loginType' }));

  const loginVariants = [
    userData?.isGoogleAuthenticatorSetUp,
    userData?.emailVerified,
    userData?.phoneVerified,
  ].filter((el) => !!el).length;

  return (
    <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
      <VerifiedDetails title={title} />
      <Box sx={{ mt: '30px' }}>
        <Typography variant="h6" mb={1}>
          {userData?.isGoogleAuthenticatorSetUp
            ? 'Google authenticator confirmed'
            : 'Sign Up with google'}
        </Typography>
        <Button
          sx={{
            color: userData?.isGoogleAuthenticatorSetUp ? 'red' : 'inherit',
          }}
          variant={userData?.isGoogleAuthenticatorSetUp ? 'text' : 'outlined'}
          endIcon={<GoogleIcon />}
          onClick={googleLoginClick}
        >
          {userData?.isGoogleAuthenticatorSetUp ? 'Delete' : 'Open'}
        </Button>
      </Box>
      {loginVariants > 1 && (
        <Box sx={{ mt: '30px' }}>
          <Typography variant="h6" mb={1}>
            Change Login type
          </Typography>
          <Button
            variant={'outlined'}
            endIcon={<LoginIcon />}
            onClick={loginTypeClick}
          >
            Change
          </Button>
        </Box>
      )}
      <AccountConfirmationBtn />
    </Grid>
  );
}
