import { useState, FC } from 'react';
import {
  IconButton,
  Menu,
  List,
  Button,
  MenuItem,
  Grid,
  Typography,
  ListItem,
  Divider,
} from '@mui/material';
import { Wallet } from '@mui/icons-material';
import { Box } from '@mui/material';
import { EXCHANGE_COIN_LIST } from 'shared';
import { Crypto } from 'store/wallet/types';
import { useCheckKYC, useBalance } from 'hooks';
import { formatPrice } from 'utils';
import { Navigate } from 'react-router-dom';
import { AvatarComponent } from '../avatar_component/avatar.component';

export const AccountMenu: FC = () => {
  const { check: checkKYC } = useCheckKYC();
  const { balance } = useBalance();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const dispatch = useDispatch();
  const [isLogged, setIsLogged] = useState<boolean>(true);
  // const { data: userData } = useGetUserQuery();
  // const { showNotification } = useNotification();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!checkKYC()) return;

    setAnchorEl(event.currentTarget);
  };

  // const onWithdraw = () => {
  //   if (userData?.Ibans?.length === 0) {
  //     showNotification('Please add IBAN address in user profile', 'warning');
  //     return;
  //   }
  //   dispatch(setModalState({ visible: true, name: 'withdraw' }));
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenKyc');
    setIsLogged(false);
  };

  if (!isLogged) return <Navigate to="/auth" />;

  return (
    <Box>
      <IconButton
        aria-controls="user-account-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: 'background.default',
        }}
      >
        <AvatarComponent disableUpload height={35} width={35} />
      </IconButton>
      <Menu
        id="user-account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Wallet />
            <Typography ml={1}>Fiat</Typography>
          </Box>
          <List sx={{ padding: 0 }}>
            {(Object.entries(balance?.withdraw || {}) || [])?.map(
              ([key, value]) => {
                return (
                  <ListItem
                    sx={{
                      padding: 0,
                      display: 'flex',
                      mb: 1,
                      flexBasis: '50%',
                    }}
                    key={key}
                  >
                    {key}: {formatPrice(value.total as unknown as string)}
                  </ListItem>
                );
              }
            )}
          </List>
        </MenuItem>
        <Divider />
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Wallet />
            <Typography ml={1} mb={1}>
              Crypto
            </Typography>
          </Box>
          <List sx={{ padding: 0 }}>
            {EXCHANGE_COIN_LIST.map((ec, index) => {
              const price = balance?.deposit?.[ec.symbol as Crypto]?.total || 0;
              return (
                <ListItem
                  sx={{ padding: 0, display: 'flex', mb: 1 }}
                  key={ec.id + index}
                >
                  <Box width={30} height={30} mr={1}>
                    {ec.icon}
                  </Box>
                  {ec.symbol}: {formatPrice(price)}
                </ListItem>
              );
            })}
          </List>
        </MenuItem>
        <Divider />
        <MenuItem disableRipple disableTouchRipple>
          <Grid container spacing={1} justifyContent="space-between">
            {/* <Grid item>
              <Button variant="contained" onClick={onWithdraw}>
                Withdraw
              </Button>
            </Grid> */}
            <Grid item flexBasis={1}>
              <Button variant="contained" onClick={logOutHandler}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </Box>
  );
};
