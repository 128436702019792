import { FC, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Grid, styled } from '@mui/material';
import { AccountMenu } from '../menu';
import Logo from 'assets/logo.svg';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { Notifications } from 'components/notifications/notifications';

const pages = [
  { name: 'Dashboard', route: '/', icon: <SpaceDashboardIcon /> },
  { name: 'Exchange', route: '/exchange', icon: <CurrencyExchangeIcon /> },
  {
    name: 'Transaction History',
    route: '/transaction-history',
    icon: <HistoryToggleOffIcon />,
  },
  { name: 'Profile', route: '/user-profile', icon: <PersonIcon /> },
];

const NavLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const drawerWidth = 240;

type HeaderProps = {
  window?: () => Window;
};

export const Header: FC<HeaderProps> = ({ window }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNotificationsToggle = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {pages.map(({ route, name, icon }) => (
          <ListItem key={route} disablePadding onClick={handleDrawerToggle}>
            <NavLink to={route} key={route} sx={{ width: '100%' }}>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: 'grey.900' }}>
      <Box sx={{ maxWidth: '100%', padding: { xs: '0 16px', md: '0 40px' } }}>
        <Toolbar disableGutters>
          <NavLink
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={Logo} height="50" alt="qbs" />
          </NavLink>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Typography
            component="a"
            href=""
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
              width: '140px',
            }}
          >
            <img src={Logo} height="50" alt="qbs" />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              color: 'secondary.main',
            }}
          >
            {pages.map(({ name, route }) => (
              <NavLink to={route} key={route}>
                {name}
              </NavLink>
            ))}
          </Box>
          <Grid
            container
            sx={{
              justifyContent: 'flex-end',
              wrap: 'nowrap',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Grid item>
              <Notifications
                isOpen={notificationsOpen}
                onToggle={handleNotificationsToggle}
              />
            </Grid>
            <Grid item>
              <AccountMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
