import { useGetUserQuery } from 'services';
import { useNotification } from './useNotification';

export const useCheckKYC = () => {
  const { data: user } = useGetUserQuery();
  const { showNotification } = useNotification();

  const checkHandle = () => {
    if (!user?.kycVerified) {
      showNotification('You need to finished KYC process.', 'warning');
    }

    return !!user?.kycVerified;
  };

  return {
    check: checkHandle,
    isVerified: !!user?.kycVerified,
  };
};
