import { FC, useMemo } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Tooltip, IconButton, Divider, ClickAwayListener } from '@mui/material';
import { useWSNotifications } from 'hooks/useWSNotifications';
import { Notification } from './notification';
import styled from '@emotion/styled';

type NotificationsProps = {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
};

const NotificationsWrapper = styled('div')`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 1000;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow: auto;
`;

const Dot = styled('span')`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
`;

export const Notifications: FC<NotificationsProps> = ({
  onToggle,
  isOpen = false,
}) => {
  const { notifications, hasUnreadNotifications, onReadHandler } =
    useWSNotifications();

  const tooltipText = useMemo(() => {
    return hasUnreadNotifications
      ? 'You have new unread notifications'
      : 'No new notifications';
  }, [hasUnreadNotifications]);

  const handleToggleNotifications = () => {
    if (hasUnreadNotifications) {
      onToggle(!isOpen);
    }
  };

  const onClickAwayListener = () => {
    onToggle(false);
  };

  return (
    <>
      <Tooltip title={tooltipText} placement="bottom">
        <IconButton
          size="large"
          aria-label="show notifications"
          aria-haspopup="true"
          onClick={handleToggleNotifications}
          color="inherit"
        >
          <NotificationsIcon />
          {hasUnreadNotifications && <Dot />}
        </IconButton>
      </Tooltip>
      {isOpen && hasUnreadNotifications && (
        <ClickAwayListener onClickAway={onClickAwayListener}>
          <NotificationsWrapper>
            {notifications?.map((notification, index) => (
              <>
                <Notification
                  notification={notification}
                  onReadHandler={onReadHandler}
                  key={index}
                />
                {index !== notifications.length - 1 && <Divider />}
              </>
            ))}
          </NotificationsWrapper>
        </ClickAwayListener>
      )}
    </>
  );
};
