import { createBrowserRouter } from 'react-router-dom';
import { AppLayout, AuthLayout } from 'layouts';
import {
  AuthVerifyEmailPage,
  ForgotPage,
  UserProfile,
  ExchangePage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  OperationHistoryPage,
  DashboardPage,
} from 'pages';
import { GoogleAuthPage } from '../pages/login/google.auth.page';
import { Settings } from 'pages/settings/settings.page';

export const routes = createBrowserRouter([
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      { path: '', element: <LoginPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'forgot', element: <ForgotPage /> },
      { path: 'reset-password', element: <ResetPasswordPage /> },
      { path: 'register-verify', element: <AuthVerifyEmailPage /> },
      { path: 'login-verify', element: <AuthVerifyEmailPage /> },
      { path: 'google-verify', element: <GoogleAuthPage /> },
    ],
  },
  {
    path: '',
    element: <AppLayout />,
    children: [
      { path: '', element: <DashboardPage /> },
      { path: 'exchange', element: <ExchangePage /> },
      {
        path: 'user-profile',
        element: <UserProfile />,
        children: [
          {
            path: 'kyc',
            element: <UserProfile />,
          },
          {
            path: 'password-change',
            element: <UserProfile />,
          },
          {
            path: 'profile',
            element: <UserProfile />,
          },
          {
            path: 'settings',
            element: <Settings />,
          },
        ],
      },
      { path: 'transaction-history', element: <OperationHistoryPage /> },
    ],
  },
  { path: '*', element: <h1>Not found page</h1> },
]);
