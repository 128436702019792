const ROOTS_AUTH = '/auth';
const ROOTS_USER = '/user';
const ROOTS_REVIEW = '/review';
const ROOTS_ADMIN = '/admin';
const ROOTS_WITHDRAW = '/withdraw';
const ROOTS_PDF = '/pdf';
const ROOTS_COMPLIENCE_OFFICER = '/complience-officer';
const ROOTS_ACCOUNT_STATEMENTS = '/account-statement';
const ROOT_NOTIFICATIONS = '/notification';

export const PATH_AUTH_API = {
  ROOT: ROOTS_AUTH,
  LOGIN: `${ROOTS_AUTH}/login`,
  REGISTER: `${ROOTS_AUTH}/register`,
  FORGOT_PASSWORD: `${ROOTS_AUTH}/forgot-password`,
  RESET_PASSWORD: `${ROOTS_AUTH}/reset-password`,
  CONFIRM_VERIFICATION: `${ROOTS_AUTH}/verify`,
  GET_USER: `${ROOTS_AUTH}/user/profile`,
  SEND_2FA_CODE: `${ROOTS_AUTH}/transaction-send-code`,
  VERIFY_2FA_CODE: `${ROOTS_AUTH}/transaction-verify-code`,
  GENERATE_2FA_TOTP: `${ROOTS_AUTH}/2fa-totp/generate`,
  VERIFY_LOGIN_2FA_TOTP: `${ROOTS_AUTH}/login/2fa-totp/verify`,
  VERIFY_2FA_TOTP: `${ROOTS_AUTH}/2fa-totp/verify`,
  SEND_PHONE_CODE: `${ROOTS_AUTH}/register/phone/send-code`,
  SEND_EMAIL_CODE: `${ROOTS_AUTH}/register/email/send-code`,
  VERIFY_PHONE_CODE: `${ROOTS_AUTH}/register/phone`,
  CHANGE_2FA: `${ROOTS_AUTH}/2fa`,
};

export const PATH_USER_API = {
  ROOT: ROOTS_USER,
  PROFILE: `${ROOTS_USER}/profile`,
  BALANCE_HISTORY: `${ROOTS_USER}/balance-history`,
  PASSWORD: `${ROOTS_USER}/password`,
  BALANCE: `${ROOTS_USER}/balance`,
  AVAILABLE_BALANCE: `${ROOTS_USER}/system-balance`,
  IBAN: `${ROOTS_USER}/iban`,
  CHANGE_2FA: `${ROOTS_USER}/2fa`,
  DELETE_2FA_TOTP: `${ROOTS_USER}/2fa-totp`,
  UPLOAD_AVATAR: `${ROOTS_USER}/avatar`,
};

export const PATH_USER_PDF_API = {
  ROOT: ROOTS_PDF,
  ACCOUNT_CONFIRMATION: `${ROOTS_PDF}/account-confirmation`,
  ACCOUNT_STATEMENT: `${ROOTS_PDF}/account-statement`,
};

export const PATH_ACCOUNT_STATEMENT_API = {
  ROOT: ROOTS_ACCOUNT_STATEMENTS,
  ACCOUNT_STATEMENT_FILTERS: `${ROOTS_ACCOUNT_STATEMENTS}/filters`,
};

export const PATH_COMPLIENCE_OFFICER_API = {
  ROOT: ROOTS_COMPLIENCE_OFFICER,
  ACCOUNT_CONFIRMATION: `${ROOTS_COMPLIENCE_OFFICER}/pdf/account-confirmation`,
  ACCOUNT_CONFIRMATION_VALIDATE: `${ROOTS_COMPLIENCE_OFFICER}/pdf/validate`,
};

export const PATH_NOTIFICATIONS_API = {
  UNREAD: `${ROOT_NOTIFICATIONS}/unread`,
};

export const PATH_TRANSACTIONS_API = {
  WITHDRAW_CRYPTO: '/withdraw/request/crypto',
  WITHDRAW_FIAT: '/withdraw/request/fiat',
  EXCHANGE: '/exchange/send',
  EXCHANGE_FEE: '/exchange/fee',
  HISTORY: '/transaction-history',
};

export const PATH_REVIEW_API = {
  ROOT: ROOTS_REVIEW,
  USER: `${ROOTS_REVIEW}/user`,
  MY: `${ROOTS_REVIEW}/my`,
};

export const PATH_ADMIN_API = {
  ROOT: ROOTS_ADMIN,
  USERS: `${ROOTS_ADMIN}/users`,
  USERS_IBANS: `${ROOTS_ADMIN}/users/ibans`,
  USERS_IBANS_VERIFY: `${ROOTS_ADMIN}/users/iban`,
  TRANSACTIONS: `${ROOTS_ADMIN}/transactions`,
  SYSTEM_BALANCE_DOPOSITE: `${ROOTS_ADMIN}/transactions/system-balance-deposit`,
};

export const PATH_WITHDRAW_API = {
  ROOT: ROOTS_WITHDRAW,
  REQUESTS: `${ROOTS_WITHDRAW}/requests`,
  REQUESTS_SUBMIT: `${ROOTS_WITHDRAW}/submit`,
  REQUESTS_STATUS: `${ROOTS_WITHDRAW}/status`,
};
