import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  CardContent,
  Dialog,
  Divider,
  FormHelperText,
  Stack,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import { useGetUserQuery, useUploadAvatarMutation } from '../../../services';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useNotification, useServerError } from '../../../hooks';
import { formatBytes } from '../../../helpers/bytesTransformHelper';

type ChangeLoginTypeProps = {
  open: boolean;
  onClose: () => void;
};

const AVAILABLE_IMAGE_SIZE = 700000;

export const AvatarUploadModal: FC<ChangeLoginTypeProps> = memo(
  ({ open, onClose }) => {
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [imageFile, setImageFile] = useState<any>(null);

    const { showNotification } = useNotification();

    const { data: userData } = useGetUserQuery();

    const [
      uploadAvatar,
      {
        isError: isErrorUploadAvatar,
        error: errorUploadAvatar,
        isSuccess: isSuccessUploadAvatar,
        isLoading: isLoadingUploadAvatar,
      },
    ] = useUploadAvatarMutation();

    const onSave = () => {
      const data = new FormData();
      data.append('image', imageFile);

      uploadAvatar(data);
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setImageFile(file);
        const reader = new FileReader();

        reader.onloadend = () => {
          setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
      }
    };

    const imageSizeError =
      imageFile?.size && +imageFile?.size >= AVAILABLE_IMAGE_SIZE;

    useServerError({ isError: isErrorUploadAvatar, error: errorUploadAvatar });

    useEffect(() => {
      if (isSuccessUploadAvatar) {
        onClose();
        showNotification('Avatar updated!', 'success');
      }
    }, [isSuccessUploadAvatar]);

    useEffect(() => {
      if (userData?.profileImage) {
        setImageUrl(userData?.profileImage);
      } else {
        setImageUrl(null);
      }
      setImageFile(null);
    }, [userData, open]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="Upload Your avatar" />
          <Divider />
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{ margin: '10px 0' }}
          >
            <Avatar
              src={imageUrl && typeof imageUrl === 'string' ? imageUrl : ''}
              alt="Uploaded Image"
              sx={{ height: '300px', width: '300px' }}
            />
            <label
              htmlFor="upload-image"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {imageSizeError && (
                <FormHelperText sx={{ textAlign: 'center' }} error>
                  Your image size {formatBytes(imageFile?.size)} <br />
                  Image size must be less or equal 700 KB
                </FormHelperText>
              )}
              <Button
                variant="contained"
                component="span"
                sx={{ width: '100px' }}
              >
                Upload
              </Button>
              <input
                id="upload-image"
                hidden
                accept="image/*"
                type="file"
                onChange={handleFileUpload}
              />
            </label>
          </Stack>
          <Divider />
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            loading={isLoadingUploadAvatar}
            variant="contained"
            onClick={onSave}
            sx={{ mt: 3, ml: 1 }}
            disabled={imageSizeError}
          >
            Save
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  }
);
