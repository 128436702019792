export const calculateInverseRate = (rate: string): string => {
  const numericRate = parseFloat(rate);
  if (isNaN(numericRate) || numericRate === 0) {
    return '0';
  }
  return (1 / numericRate).toFixed(6);
};

export const calculateAdditionalPairs = (rates: any) => {
  const additionalPairs: any = {};
  const reachedInverseRatesPairs = ['EURUSD', 'GBPUSD', 'EURGBP'];

  reachedInverseRatesPairs.forEach((pair) => {
    if (!rates[pair]) {
      return;
    }
    const curr1 = pair.slice(0, 3);
    const curr2 = pair.slice(3);

    additionalPairs[`${curr2}${curr1}`] = {
      price: calculateInverseRate(rates[`${curr1}${curr2}`].price),
    };
  });

  return { ...rates, ...additionalPairs };
};
