import React, { useEffect, FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  Typography,
  SxProps,
  Theme,
  Skeleton,
} from '@mui/material';
import { Crypto, Fiat } from 'store/wallet/types';
import { EXCHANGE_COIN_LIST } from 'shared/currencies/currencies';
import { Box } from '@mui/material';
import {
  useBalance,
  useDebounce,
  useNotification,
  useServerError,
} from 'hooks';
import { Cryptocurrencies } from 'pages/exchange/exchange.page';
import { TwoFaModalComponent } from 'shared';
import {
  useExchangeMutation,
  useGetSystemBalanceQuery,
  useGetExchangeFeeQuery,
  useGetUserQuery,
} from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'store';
import { setExchangeWaiting, setModalState } from 'store/ui';
import { formatPrice } from 'utils';
import { getNormalizedCrypto } from 'helpers/normalizeCryptoNames';

type ExchangeFormProps = {
  withCloseButton?: boolean;
  exchangeBtnFullWidth?: boolean;
  prices: Cryptocurrencies;
  symbol?: Crypto;
  onClose?: () => void;
  loadingSx?: SxProps<Theme>;
};

type ExchangeFormData = {
  amount: number;
  fromCurrency: string;
  toCurrency: string;
};

const MIN = 0.0005;

export const ExchangeForm: FC<ExchangeFormProps> = ({
  onClose = () => {},
  prices,
  symbol = 'ETH',
  withCloseButton = false,
  exchangeBtnFullWidth = false,
}) => {
  const [selectedSymbol, setSymbol] = useState<Crypto>(symbol);
  const [finalValue, setFinalValue] = useState<number>(0);
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const [exchangeRate, setExchangeRate] = useState<number | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<Fiat>('USD');

  const [exchange] = useExchangeMutation();
  const dispatch = useAppDispatch();
  const { data: userProfile } = useGetUserQuery();
  const { balance } = useBalance();

  const { data: global } = useGetSystemBalanceQuery(
    {},
    { pollingInterval: 10000 }
  );

  const {
    data: feeInfo,
    isFetching: isFetchingFee,
    error: errorFee,
    isError: isErrorFee,
  } = useGetExchangeFeeQuery(
    {
      amount: selectedAmount,
      assetId: balance?.deposit?.[selectedSymbol as Crypto]?.value as string,
    },
    { skip: !selectedAmount }
  );

  useServerError({ isError: isErrorFee, error: errorFee });

  const { showNotification } = useNotification();
  const cryptoBalance = useMemo(
    () => balance?.deposit?.[selectedSymbol]?.total || 0,
    [selectedSymbol, balance?.deposit]
  );

  const systemBalance = global?.systemBalance.find(
    (sb) => sb.fiat === selectedCurrency
  )?.total;

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    trigger,
    reset,
  } = useForm<ExchangeFormData>({
    resolver: yupResolver(
      yup.object().shape({
        amount: yup
          .number()
          .typeError('Amount must be a number')
          .transform((value) => (isNaN(value) ? undefined : value))
          .default(undefined)
          .max(cryptoBalance, `Not enough balance to exchange.`)
          .required('Amount is required')
          .test({
            name: 'minimalBalance',
            message: `Minimal amount to exchange: ${(
              MIN / (exchangeRate || 1)
            ).toFixed(4)}`,
            test: function (value: number) {
              return value >= MIN / (exchangeRate || 1);
            },
          })
          .test({
            name: 'availableBalance',
            message: `Amount must be less than or equal to ${
              systemBalance || 0
            }`,
            test: function (value: number) {
              const balanceValue = systemBalance || 0;
              return value <= (balanceValue as number);
            },
          }),
      })
    ),
    mode: 'onChange',
  });

  const watchAmount = watch('amount');
  const watchSymbol = watch('fromCurrency');

  const selectedAmountHandler = () => {
    if (!Object.entries(errors).length) {
      setSelectedAmount(watchAmount);
    } else {
      setSelectedAmount(0);
    }
  };

  useDebounce({
    effect: selectedAmountHandler,
    dependencies: [watchAmount, selectedSymbol],
    delay: 500,
  });

  useEffect(() => {
    if (watchAmount) {
      trigger('amount');
    }
  }, [cryptoBalance, watchAmount, selectedSymbol]);

  useEffect(() => {
    if (watchSymbol) {
      const normalizedCrypto = getNormalizedCrypto(
        watchSymbol as Crypto,
        selectedCurrency
      );
      const rate = parseFloat(prices[normalizedCrypto]?.price as any);

      setSymbol(() => watchSymbol as Crypto);
      setExchangeRate(() => rate);
    }

    if (prices && watchAmount) {
      const normalizedCrypto = getNormalizedCrypto(
        watchSymbol as Crypto,
        selectedCurrency
      );
      const rate = parseFloat(prices[normalizedCrypto]?.price as any);
      const feeRes = feeInfo?.fee ? feeInfo?.fee : 0;
      const finalValueRes = (watchAmount - watchAmount * feeRes) * rate;

      setFinalValue(() => finalValueRes);
    }
  }, [prices, watchSymbol, watchAmount, feeInfo]);

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    const newCurrency = event.target.value as Fiat;
    setSelectedCurrency(newCurrency);
  };

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleExchange = async (data: ExchangeFormData, code: string) => {
    const { amount, fromCurrency } = data;
    const asset = balance?.deposit?.[fromCurrency as Crypto]?.value;
    if (!asset) {
      return;
    }
    try {
      await exchange({
        assetId: asset,
        amount,
        fiat: selectedCurrency,
        code,
      });
      dispatch(setExchangeWaiting({ status: true }));
      reset();
      onModalClose();

      if (!userProfile?.hasLeaveFeedback) {
        dispatch(setModalState({ visible: true, name: 'reviews' }));
      }
    } catch {
      showNotification('Transaction is failed', 'error');
    }
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;

    handleSubmit(async (data) => await handleExchange(data, code))();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form>
        <TextField
          fullWidth
          margin="normal"
          label="Amount"
          type="number"
          {...register('amount', { required: 'Amount is required' })}
          error={!!errors.amount}
          helperText={errors.amount?.message}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="from-currency-label">From Currency</InputLabel>
          <Select
            label="From Currency"
            labelId="from-currency-label"
            id="from-currency-select"
            {...register('fromCurrency', {
              required: 'From Currency is required',
            })}
            error={!!errors.fromCurrency}
            defaultValue={symbol}
            renderValue={(selected) =>
              EXCHANGE_COIN_LIST.filter((ec) => ec.symbol === selected).map(
                (ec) => (
                  <Box
                    key={ec.id}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    <Box width={30} height={30} mr={1}>
                      {ec.icon}
                    </Box>
                    {ec.symbol}
                  </Box>
                )
              )
            }
          >
            {EXCHANGE_COIN_LIST.map((ec) => (
              <MenuItem
                key={ec.id}
                value={ec.symbol}
                selected={symbol === ec.symbol}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box width={30} height={30} mr={1}>
                  {ec.icon}
                </Box>
                {ec.symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="to-currency-label">To Currency</InputLabel>
          <Select
            label="To Currency"
            labelId="to-currency-label"
            id="to-currency-select"
            {...register('toCurrency', {
              required: 'To Currency is required',
            })}
            error={!!errors.toCurrency}
            defaultValue="USD"
            value={selectedCurrency}
            onChange={handleCurrencyChange}
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="GBP">GBP</MenuItem>
            {/* <MenuItem value="AED" disabled>
              AED
            </MenuItem> */}
          </Select>
        </FormControl>
        {watchSymbol && symbol && (
          <>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Exchange Rate: {formatPrice(exchangeRate || 0, 4)}{' '}
              {selectedCurrency} = 1 {watchSymbol}
            </Typography>
            {watchAmount && watchSymbol ? (
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                Fee:{' '}
                {isFetchingFee ? (
                  <Skeleton variant="text" sx={{ width: '50px' }} />
                ) : feeInfo && !isErrorFee && !Object.entries(errors).length ? (
                  feeInfo?.fee.toFixed(5)
                ) : (
                  ''
                )}{' '}
                {selectedSymbol}
              </Typography>
            ) : null}
            {finalValue ? (
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Final Value: {finalValue.toFixed(2)} {selectedCurrency}
              </Typography>
            ) : null}
          </>
        )}
        <DialogActions>
          {withCloseButton && (
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          )}
          <TwoFaModalComponent
            disabled={Object.keys(errors).length !== 0}
            type="button"
            fullWidth={exchangeBtnFullWidth}
            variant="contained"
            color="primary"
            onComplete={confirm2FaHandle}
            skip={false}
          >
            Exchange
          </TwoFaModalComponent>
        </DialogActions>
      </form>
    </Box>
  );
};
