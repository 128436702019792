// import { BTCLogo } from "assets/icons/btc";
import { ADALogo } from 'assets/icons/ada';
import { ETHLogo } from 'assets/icons/eth';
import { EURLogo } from 'assets/icons/eur';
import { GBPLogo } from 'assets/icons/gbp';
import { LTCLogo } from 'assets/icons/ltc';
import { MATIC } from 'assets/icons/matic';
import solanaLogo from 'assets/icons/solana-sol-logo.svg';
import { USDLogo } from 'assets/icons/usd';
import { USDCLogo } from 'assets/icons/usdc';
import { USDTLogo } from 'assets/icons/usdt';
import { ReactNode } from 'react';
import { Crypto } from 'store/wallet/types';

export type ExchangeCoin = {
  symbol: Crypto;
  icon: ReactNode;
  name: string;
  id: number;
  protocol: string;
  network: string;
};

export const EXCHANGE_COIN_LIST: ExchangeCoin[] = [
  {
    protocol: 'ethereum',
    network: 'mainnet',
    name: 'Ethereum',
    symbol: 'ETH',
    icon: <ETHLogo />,
    id: 1,
  },
  {
    protocol: 'ethereum',
    network: 'mainnet',
    name: 'USDC Coin',
    symbol: 'USDC',
    icon: <USDCLogo />,
    id: 2,
  },
  {
    protocol: 'ethereum',
    network: 'mainnet',
    name: 'Tether',
    symbol: 'USDT',
    icon: <USDTLogo />,
    id: 3,
  },
  {
    protocol: 'cardano',
    network: 'mainnet',
    name: 'Cardano',
    symbol: 'ADA',
    icon: <ADALogo />,
    id: 4,
  },
  {
    protocol: 'litecoin',
    network: 'mainnet',
    name: 'Litecoin',
    symbol: 'LTC',
    icon: <LTCLogo />,
    id: 5,
  },
  {
    protocol: 'ethereum',
    network: 'polygon',
    name: 'MATIC',
    symbol: 'MATIC',
    icon: <MATIC />,
    id: 6,
  },
  {
    protocol: 'solana',
    network: 'mainnet',
    name: 'Solana',
    symbol: 'SOL',
    icon: <img src={solanaLogo} alt="solana" />,
    id: 7,
  },
  // { name: 'Bitcoin', symbol: "BTC", icon: <BTCLogo />, id: 2 },
];

export const FIAT_CURRENCY_LIST = [
  {
    name: 'United States Dollar',
    symbol: 'USD',
    sign: '$',
    icon: <USDLogo />,
    id: 1,
  },
  { name: 'Euro', symbol: 'EUR', sign: '€', icon: <EURLogo />, id: 2 },
  {
    name: 'Great Britain Pound',
    symbol: 'GBP',
    sign: '£',
    icon: <GBPLogo />,
    id: 3,
  },
  // {
  //   name: 'Arab Emirates Dirham',
  //   symbol: 'AED',
  //   sign: 'د.إ',
  //   icon: <AEDLogo />,
  //   id: 4,
  // },
];
