import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { Wallets } from './wallets';
import { AccountStatementsBlock } from './account_statement_block/account.statement.block';

interface SidebarProps {}

export const Sidebar: FC<SidebarProps> = () => {
  return (
    <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
      <Box>
        <Wallets />
      </Box>
      <Box sx={{ mt: '30px' }}>
        <AccountStatementsBlock />
      </Box>
    </Grid>
  );
};
