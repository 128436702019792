// created by Artem
import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { LockOutlined } from '@mui/icons-material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { LoginFormValues } from 'types/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { emailScheme, passwordScheme } from 'utils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useServerError } from 'hooks';
import { useLoginMutation } from 'services';
import { PATH_REGISTER } from '../../constants/spa-routes';
import { TwoFactorTypesEnum } from '../../types/user.interface';
import { useSendVerificationCode } from '../../hooks/useSendVerificationCode';
import { LoadingButton } from '../../components/buttons_collection/loading.button';

type LoginPageProps = {};
export const LoginPage: FC<LoginPageProps> = () => {
  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password'
  );
  const [lowercaseEmail, setLowercaseEmail] = useState('');

  const navigate = useNavigate();

  const [login, { data: loginData, isError, error, isLoading }] =
    useLoginMutation();
  const { sendEmailCode, sendPhoneCode } = useSendVerificationCode();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }),
        password: passwordScheme({ required: true }),
      })
    ),
  });

  const { email, password } = getValues();

  const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
    login(data);
  };

  const changeEmailHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setLowercaseEmail(e.target.value.toLowerCase());

  const changePassTypeHandle = () =>
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));

  useServerError({ isError, error });

  const isGoogle2Fa =
    loginData?.twoFactorType === TwoFactorTypesEnum.GOOGLE_AUTHENTICATOR;
  const isEmail2Fa = loginData?.twoFactorType === TwoFactorTypesEnum.EMAIL;
  const isSms2Fa = loginData?.twoFactorType === TwoFactorTypesEnum.SMS;

  useEffect(() => {
    if (loginData) {
      if (loginData?.emailVerified && loginData?.phoneVerified) {
        if (!loginData?.isLogin2faEnabled) {
          if (loginData?.token) {
            localStorage.setItem('token', loginData?.token);
            navigate('/');
          }
        }
        if (isGoogle2Fa) {
          navigate(PATH_REGISTER.GOOGLE_VERIFY, {
            state: {
              email: email,
              password: password,
              verifyType: 'login',
            },
          });
        }
        if (
          (isEmail2Fa && loginData?.isLogin2faEnabled) ||
          (isSms2Fa && loginData?.isLogin2faEnabled)
        ) {
          isEmail2Fa && sendEmailCode({ email });
          isSms2Fa && sendPhoneCode({ phone: loginData.phone });
          navigate(PATH_REGISTER.LOGIN_VERIFY, {
            state: {
              email: email,
              phone: loginData.phone,
              verifyType: 'login',
              twoFactorType: loginData.twoFactorType,
            },
          });
        }
      }
      if (!loginData?.emailVerified) {
        sendEmailCode({ email });
        navigate(PATH_REGISTER.REGISTER_EMAIL_VERIFY, {
          state: {
            email: email,
            phone: loginData.phone,
            verifyType: 'register',
          },
        });
      } else if (!loginData?.phoneVerified) {
        sendPhoneCode({ phone: loginData.phone });
        navigate(PATH_REGISTER.REGISTER_PHONE_VERIFY, {
          state: {
            email: email,
            phone: loginData.phone,
            verifyType: 'register',
          },
        });
      }
    }
  }, [loginData]);

  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          {...register('email')}
          error={!!errors?.email?.message}
          helperText={errors?.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={lowercaseEmail}
          onChange={changeEmailHandler}
          autoFocus
          sx={{ textTransform: 'lowercase' }}
        />
        <TextField
          {...register('password')}
          error={!!errors?.password?.message}
          helperText={errors?.password?.message}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={passwordType}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={changePassTypeHandle}>
                  {passwordType === 'text' ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={<Checkbox {...register('remember')} color="primary" />}
          label="Remember me"
        />
        <LoadingButton
          type="submit"
          isLoading={isLoading}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link variant="body2" component={NavLink} to="forgot">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link variant="body2" component={NavLink} to="register">
              Don't have an account? Sign Up
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
