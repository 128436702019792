import { number, ref, string } from 'yup';
import IBAN from 'iban';

interface ISchemeOptions {
  required?: boolean;
  min?: number;
  max?: number;
}

export const emailScheme = (opt?: ISchemeOptions) => {
  const rule = string().email();

  if (opt?.required) return rule.required();

  return rule;
};

export const passwordScheme = (opt?: ISchemeOptions) => {
  const rule = string()
    .min(opt?.min || 8)
    .max(opt?.max || 16)
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter');

  if (opt?.required) return rule.required();

  return rule;
};

export const passwordConfirmScheme = (opt?: ISchemeOptions) => {
  const rule = string()
    .min(opt?.min || 8)
    .max(opt?.max || 16)
    .oneOf([ref<any>('password')], 'Your passwords do not match.');

  if (opt?.required) return rule.required();

  return rule;
};

export const stringScheme = (opt?: ISchemeOptions) => {
  const rule = string().min(opt?.min || 0);
  if (opt?.required) return rule.required();

  return rule;
};

export const ibanScheme = (opt?: ISchemeOptions) => {
  const rule = string().test(
    'iban',
    'Invalid IBAN (example: BE68539007547034)',
    (value) => (value ? IBAN?.isValid?.(value) : true)
  );

  if (opt?.required) return rule.required();

  return rule;
};

export const nameScheme = (opt?: ISchemeOptions) => {
  const rule = string()
    .min(opt?.min || 0)
    .max(opt?.max || 99999);

  if (opt?.required) return rule.required();

  return rule;
};

export const totalScheme = (opt?: ISchemeOptions) => {
  const rule = number()
    .min(opt?.min || 0)
    .max(opt?.max || 99999);

  if (opt?.required) return rule.required();

  return rule;
};

export const phoneNumberScheme = (opt?: ISchemeOptions) => {
  const rule = string()
    .min(opt?.min || 5, 'Minimum 5 digits')
    .max(opt?.max || 15, 'Maximum 15 digits');

  if (opt?.required) return rule.required();

  return rule;
};
