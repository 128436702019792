// created by Artem
import { FC } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

type OperationHistoryFiltersComponentProps = {
  onFilter?: (type: string, value: string) => void;
};
export const OperationHistoryFiltersComponent: FC<
  OperationHistoryFiltersComponentProps
> = ({ onFilter }) => {
  const changeTypeHandle: SelectInputProps['onChange'] = ({ target }) =>
    onFilter?.('type', `${target.value}`);

  const changeOrderHandle: SelectInputProps['onChange'] = ({ target }) =>
    onFilter?.('order', `${target.value}`);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl fullWidth>
          <InputLabel>Filter by Type</InputLabel>
          <Select
            onChange={changeTypeHandle}
            defaultValue="All"
            label="Filter By Type"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="exchanges">Exchange</MenuItem>
            <MenuItem value="withdrawals">Withdraw</MenuItem>
            <MenuItem value="deposits">Receive</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl fullWidth>
          <InputLabel id="sort-label">Sort by Date</InputLabel>
          <Select
            onChange={changeOrderHandle}
            defaultValue="desc"
            label="Sort by Date"
          >
            <MenuItem value="desc">Descending</MenuItem>
            <MenuItem value="asc">Ascending</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
