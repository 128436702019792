export interface IIbans {
  account: string;
  createdAt: string;
  id: number;
  isVerified: boolean;
  updatedAt: string;
}

export interface IRequestedPdf {
  createdAt: string;
  expireAt: null;
  id: number;
  isApproved: boolean;
  isValid: boolean;
  updatedAt: string;
}

export enum TwoFactorTypesEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  GOOGLE_AUTHENTICATOR = 'GOOGLE_AUTHENTICATOR',
}

export interface IUser {
  email: string;
  emailVerified: boolean;
  id?: number | string;
  lastName: string;
  name: string;
  phone: string;
  role?: string;
  kycVerified: boolean;
  vaultAccountId?: string | null;
  middleName: string;
  hasLeaveFeedback: boolean;
  Ibans: IIbans[];
  isGoogleAuthenticatorSetUp: boolean;
  twoFactorType: TwoFactorTypesEnum;
  phoneVerified: boolean;
  RequestedPdf: IRequestedPdf;
  isWithdraw2faEnabled: boolean;
  isSendCrypto2faEnabled: boolean;
  isLogin2faEnabled: boolean;
  withdrawLimit: number;
  sendCryptoLimit: number;
  profileImage: string | null;
  isChangeSettings2faEnabled: boolean;
  twoFactorGoogleAuthenticatorSecret: string;
}
