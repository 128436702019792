import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIState, ModalNames, SelectedCurrency } from './types';

const initialState: UIState = {
  modalsVisibility: {
    exchange: false,
    deposit: false,
    withdraw: false,
    emailVerification: false,
    notConnected: false,
    reviews: false,
    reviewsList: false,
    googleLogin: false,
    loginType: false,
    sendCrypto: false,
    sendFiat: false,
    accountStatement: false,
    avatarUploadModal: false,
  },
  isWaitingForExchange: false,
  isWithdrawalWaiting: false,
  selectedSymbol: '',
  selectedCurrency: {
    symbol: 'USD',
    rate: 1,
  },
  isCurrencyLoading: false,
};

export const uiReducer = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setModalState(
      state: UIState,
      action: PayloadAction<{
        name: ModalNames;
        visible: boolean;
        symbol?: string;
      }>
    ) {
      const { name, visible, symbol = '' } = action.payload;
      state.modalsVisibility[name] = visible;
      state.selectedSymbol = symbol as string;
    },
    setExchangeWaiting(
      state: UIState,
      action: PayloadAction<{
        status: boolean;
      }>
    ) {
      const { status } = action.payload;
      state.isWaitingForExchange = status;
    },
    setWithdrawalWaiting(
      state: UIState,
      action: PayloadAction<{
        status: boolean;
      }>
    ) {
      const { status } = action.payload;
      state.isWithdrawalWaiting = status;
    },
    setCurrency(state: UIState, action: PayloadAction<SelectedCurrency>) {
      state.selectedCurrency = action.payload;
    },
    setCurrencyLoading(state: UIState, action: PayloadAction<boolean>) {
      state.isCurrencyLoading = action.payload;
    },
  },
});

export const {
  setModalState,
  setCurrency,
  setExchangeWaiting,
  setWithdrawalWaiting,
  setCurrencyLoading,
} = uiReducer.actions;

export default uiReducer.reducer;
