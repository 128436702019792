import { FC, MouseEvent } from 'react';
import { Box, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import MarkAsReadIcon from '@mui/icons-material/CheckCircleOutline';
import {
  DepositInfo,
  EventTypeEnum,
  Notification as INotification,
  NotificationData,
  SendCryptoInfo,
  SendFiatInfo,
} from 'types/wallet.interface';
import {
  AccountBalance,
  AttachMoney,
  CalendarMonth,
  CurrencyExchange,
  Payment,
  Send,
  Wallet,
} from '@mui/icons-material';
import { transformDate } from 'helpers/dateTransformHelper';
import { DateFormatEnum } from '../../types/dateTimeFormatOption';

type NotificationProps = {
  notification: INotification;
  onReadHandler: (notification: INotification) => void;
};

export const Notification: FC<NotificationProps> = ({
  notification,
  onReadHandler,
}) => {
  const { id, createdAt, read, message } = notification;

  const handleMarkAsRead = (event: MouseEvent) => {
    event.stopPropagation();
    onReadHandler(notification);
  };

  const getIcon = (notif: INotification) => {
    switch (notif.type) {
      case EventTypeEnum.EXCHANGE: {
        return (
          <CurrencyExchange htmlColor={!notif.success ? 'red' : 'green'} />
        );
      }
      case EventTypeEnum.DEPOSIT: {
        return <Payment htmlColor={!notif.success ? 'red' : 'green'} />;
      }
      case EventTypeEnum.SEND_CRYPTO_AMOUNT:
      case EventTypeEnum.SEND_FIAT_AMOUNT:
      case EventTypeEnum.WITHDRAW_CRYPTO:
      case EventTypeEnum.WITHDRAW_SUBMIT:
      case EventTypeEnum.WITHDRAW_FIAT: {
        return <Send htmlColor={!notif.success ? 'red' : 'green'} />;
      }
    }
  };

  const getContent = (notif: INotification) => {
    const info = JSON.parse(notif?.additionalInfo || '{}') as NotificationData;
    switch (notif?.type) {
      case EventTypeEnum.EXCHANGE: {
        return (
          <Grid container sx={{ flexDirection: 'column' }}>
            <Grid item>
              <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <AttachMoney sx={{ marginRight: 1 }} />{' '}
                {(info as SendCryptoInfo)?.assetAmount}{' '}
                {(info as SendCryptoInfo)?.asset}
              </Typography>
            </Grid>
            {(info as SendCryptoInfo)?.walletAddress && (
              <Grid item>
                <Typography
                  sx={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <Wallet /> {(info as SendCryptoInfo)?.walletAddress}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }
      case EventTypeEnum.DEPOSIT: {
        return (
          <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <AttachMoney sx={{ marginRight: 1 }} />{' '}
            {(info as DepositInfo)?.assetAmount.amount}{' '}
            {(info as DepositInfo)?.asset}
          </Typography>
        );
      }
      case EventTypeEnum.SEND_CRYPTO_AMOUNT:
        return (
          <Grid container sx={{ flexDirection: 'column' }}>
            <Grid item>
              <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <AttachMoney sx={{ marginRight: 1 }} />{' '}
                {(info as SendCryptoInfo)?.assetAmount}{' '}
                {(info as SendCryptoInfo)?.asset}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <Wallet sx={{ marginRight: 1 }} />{' '}
                {(info as SendCryptoInfo)?.walletAddress}
              </Typography>
            </Grid>
          </Grid>
        );
      case EventTypeEnum.SEND_FIAT_AMOUNT:
        return info ? (
          <Grid container sx={{ flexDirection: 'column' }}>
            <Grid item>
              <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <AttachMoney sx={{ marginRight: 1 }} />
                {(info as SendFiatInfo)?.amount} {(info as SendFiatInfo)?.fiat}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <AccountBalance sx={{ marginRight: 1 }} />{' '}
                {(info as SendFiatInfo)?.iban}
              </Typography>
            </Grid>
          </Grid>
        ) : null;
    }
  };

  return (
    <Grid
      container
      sx={{
        padding: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        color: 'black',
        minWidth: '400px',
        cursor: 'pointer',
        transition: '.2s ease',
        '&:hover': {
          backgroundColor: 'rgba(150,150,150,0.1)',
        },
      }}
    >
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {getIcon(notification)}
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              marginLeft: '10px',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: '8px' }}>
              #{id} {message}
            </span>
          </div>
        </Box>
        <Tooltip title="Mark as Read" placement="top">
          <IconButton size="small" onClick={handleMarkAsRead}>
            <MarkAsReadIcon color={read ? 'disabled' : 'primary'} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Box textAlign="left">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            <CalendarMonth sx={{ marginRight: 1 }} />{' '}
            {transformDate(createdAt, DateFormatEnum.DATE_WITH_TIME_WITH_DOT)}
          </Typography>
        </Box>
        {getContent(notification)}
      </Box>
    </Grid>
  );
};
