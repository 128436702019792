import { useState, useEffect } from 'react';
import { useGetBalanceQuery } from 'services';
import {
  Deposit,
  Withdraw,
  Crypto,
  Fiat,
  UserBalance,
} from 'store/wallet/types';
import { useNotification } from './useNotification';
import { useAppDispatch, useAppSelector } from 'store';
import { setExchangeWaiting, setWithdrawalWaiting } from 'store/ui';

const FETCH_USER_BALANCE_PERIOD = 10000;

const getChangedCryptoAssets = (
  prevBalance: Deposit,
  currBalance: Deposit
): string[] => {
  const changedAssets: string[] = [];

  for (const asset in currBalance) {
    if (currBalance.hasOwnProperty(asset)) {
      const prev = prevBalance[asset as Crypto];
      const curr = currBalance[asset as Crypto];

      if (prev && curr && prev?.total !== curr?.total) {
        changedAssets.push(asset);
      }
    }
  }

  return changedAssets;
};

const getChangedFiatAssets = (
  prevBalance: Withdraw,
  currBalance: Withdraw
): string[] => {
  const changedAssets: string[] = [];

  for (const asset in currBalance) {
    if (currBalance.hasOwnProperty(asset)) {
      const prev = prevBalance[asset as Fiat];
      const curr = currBalance[asset as Fiat];

      if (prev && curr && prev?.total !== curr?.total) {
        changedAssets.push(asset);
      }
    }
  }

  return changedAssets;
};

export const useBalance = () => {
  const { data: balance, isSuccess } = useGetBalanceQuery(
    {},
    { pollingInterval: FETCH_USER_BALANCE_PERIOD }
  );
  const [prevBalance, setPrevBalance] = useState<UserBalance | null>(null);
  const [isFiatUpdated, setFiatUpdated] = useState(false);
  const [isCryptoUpdated, setCryptoUpdated] = useState(false);
  const { showNotification } = useNotification();
  const { isWaitingForExchange, isWithdrawalWaiting } = useAppSelector(
    (s) => s.uiReducer
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!prevBalance) {
      setPrevBalance(() => balance as UserBalance);
    } else if (balance && isSuccess) {
      const { withdraw: w1, deposit: d1 } = balance;
      const { withdraw: w2, deposit: d2 } = prevBalance;

      const changedFiatAssets = getChangedFiatAssets(w1, w2);
      const changedCryptoAssets = getChangedCryptoAssets(d1, d2);
      const hasCryptoUpdated = changedCryptoAssets.length !== 0;
      const hasFiatUpdated = changedFiatAssets.length !== 0;

      if (hasFiatUpdated && !hasCryptoUpdated) {
        if (isWaitingForExchange) {
          dispatch(setExchangeWaiting({ status: false }));
        }

        if (isWithdrawalWaiting) {
          dispatch(setWithdrawalWaiting({ status: false }));
        }
      }

      setFiatUpdated(() => hasFiatUpdated);
      setCryptoUpdated(() => hasCryptoUpdated);
      setPrevBalance(() => balance);
    }
  }, [
    balance,
    isSuccess,
    prevBalance,
    showNotification,
    dispatch,
    isWithdrawalWaiting,
    isWaitingForExchange,
  ]);

  return {
    total: balance?.total || 0,
    balance,
    shouldRefetchTransactions: isFiatUpdated || isCryptoUpdated,
  };
};
