import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Button, TextField, IconButton } from '@mui/material';
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  UserDataFormInterface,
  UserFormInterface,
} from '../../types/user.profile.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object } from 'yup';
import {
  emailScheme,
  ibanScheme,
  nameScheme,
  phoneNumberScheme,
  stringScheme,
} from '../../utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { theme } from '../../assets/theme';
import Paper from '@mui/material/Paper';
import {
  useGetUserQuery,
  useIbanDeleteMutation,
  useIbanUpdateMutation,
  useProfileUpdateMutation,
} from '../../services';
import { useNotification, useServerError } from '../../hooks';
import SaveIcon from '@mui/icons-material/Save';
import { Navigate } from 'react-router-dom';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { AvatarComponent } from '../../components';
import { TwoFaModalComponent } from '../../shared/modals/2fa-modal/2fa-modal.component';

const emptyIban = { value: '', id: null };

export default function UserForm() {
  const [isLogged, setIsLogged] = useState<boolean>(true);

  const { showNotification } = useNotification();

  const {
    data: userData,
    isFetching: userDataIsFetching,
    error: userDataError,
    isError: userDataIsError,
    refetch: refetchUserData,
  } = useGetUserQuery();

  const [
    updateInfo,
    {
      isLoading: isLoadingUpdateUser,
      isError: isErrorUpdateUser,
      error: errorUpdateUser,
      isSuccess: isSuccessUpdateUser,
    },
  ] = useProfileUpdateMutation();
  const [updateIban, { isError: isErrorIbanUpdate, error: errorIbanUpdate }] =
    useIbanUpdateMutation();

  const [
    deleteIban,
    {
      isError: isErrorDeleteIban,
      error: errorDeleteIban,
      isSuccess: isSuccessIbanDeleted,
    },
  ] = useIbanDeleteMutation();

  const {
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm<UserDataFormInterface>({
    defaultValues: {
      email: '',
      name: '',
      middleName: '',
      lastName: '',
      phone: '',
      iban: [emptyIban],
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }),
        name: nameScheme({ required: true, min: 3 }),
        lastName: nameScheme({ required: true, min: 3 }),
        middleName: stringScheme().nullable(),
        phone: phoneNumberScheme({ required: true }),
        iban: array(
          object({
            value: ibanScheme({ required: true }),
          })
        ).test('unique-iban', 'IBANs must be unique', function (value) {
          const ibanValues = value?.map((item) => item.value);
          const uniqueIbans = new Set(ibanValues);

          return uniqueIbans.size === value?.length;
        }),
      })
    ),
  });

  const { phone } = getValues();

  const { fields, append, remove } = useFieldArray<UserDataFormInterface>({
    name: 'iban',
    control,
  });

  const handleProfileUpdate = (data: UserFormInterface, code: string) => {
    if (data.iban?.length) {
      updateIban({ accounts: data.iban?.map((el) => el.value) as string[] });
    }
    updateInfo({
      email: data.email,
      name: data.name,
      lastName: data.lastName,
      phone: data.phone,
      middleName: data.middleName,
      ...(code ? { code } : {}),
    });
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;
    handleSubmit(async (data) => await handleProfileUpdate(data, code))();
  };

  const inputStyle = {
    width: '100%',
    padding: '10px 14px 10px 84px',
    borderColor: !!errors?.phone?.message ? theme.palette.error.main : '',
    '&:focus': {
      borderColor: theme.palette.error.main,
      boxShadow: '0 0 0 1px #CF3B27',
    },
  };

  const logOutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenKyc');
    setIsLogged(false);
  };

  const phoneHandler = (phoneNumber: string, isErrorMessage: boolean) => {
    setValue('phone', `+${phoneNumber}`);
    if (isErrorMessage) {
      trigger('phone');
    }
  };

  const removeIbanHandler = (
    item: FieldArrayWithId<UserDataFormInterface, 'iban', 'id'>,
    index: number
  ) => {
    if (item.Id) {
      deleteIban({ id: item.Id as number })
        .unwrap()
        .then(() => remove(index))
        .catch(() => null);
    } else {
      remove(index);
    }
  };

  useServerError({ isError: isErrorUpdateUser, error: errorUpdateUser });
  useServerError({ isError: userDataIsError, error: userDataError });
  useServerError({ isError: isErrorIbanUpdate, error: errorIbanUpdate });
  useServerError({ isError: isErrorDeleteIban, error: errorDeleteIban });

  useEffect(() => {
    if (userData) {
      reset({
        email: userData?.email,
        name: userData?.name,
        lastName: userData?.lastName,
        phone: userData?.phone,
        middleName: userData?.middleName,
        iban: userData?.Ibans.map((item) => {
          return { value: item.account, Id: item.id };
        }),
      });
    }
  }, [userData]);

  useEffect(() => {
    if (isSuccessUpdateUser) {
      refetchUserData();
      showNotification('User details was updated!', 'success');
    }
  }, [isSuccessUpdateUser]);

  useEffect(() => {
    if (isSuccessIbanDeleted) {
      refetchUserData();
      showNotification('IBAN was deleted!', 'success');
    }
  }, [isSuccessIbanDeleted]);

  if (!isLogged) return <Navigate to="/auth" />;
  return (
    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 } }}>
      <AvatarComponent
        hideBadge={false}
        avatarBoxSx={{ margin: 'auto', marginBottom: '15px' }}
        height={80}
        width={80}
      />
      <Typography component="h1" variant="h4" align="center">
        {userData?.name + ' ' + userData?.lastName}
      </Typography>
      <Typography variant="h6" gutterBottom>
        User profile details
      </Typography>
      <Button onClick={logOutHandler} variant="outlined">
        <span>Logout</span>
      </Button>
      <form>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    required
                    value={value}
                    onChange={onChange}
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="Email"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="name"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    required
                    fullWidth
                    value={value}
                    onChange={onChange}
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="middleName"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    required
                    fullWidth
                    value={value}
                    onChange={onChange}
                    id="middleName"
                    label="Middle Name"
                    name="middleName"
                    placeholder="Middle Name"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                name="lastName"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    margin="normal"
                    required
                    fullWidth
                    value={value}
                    onChange={onChange}
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="Last Name"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Controller
                control={control}
                name="phone"
                render={({ fieldState: { error } }) => (
                  <>
                    <PhoneInput
                      country={'us'}
                      value={phone}
                      onChange={(phoneNumber) =>
                        phoneHandler(phoneNumber, !!error?.message)
                      }
                      inputClass="phone-input"
                      inputStyle={inputStyle}
                    />
                    {!!error?.message && (
                      <Typography
                        align="left"
                        variant="caption"
                        color={theme.palette.error.main}
                        sx={{ margin: '4px 14px 0 14px', width: '100%' }}
                      >
                        {error?.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ gap: '10px', display: 'flex', flexDirection: 'column' }}
            >
              {fields.map((field, index) => {
                return (
                  <Box
                    key={field.id}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Controller
                      control={control}
                      name={`iban.${index}.value`}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          sx={{ margin: '8px 5px 8px 0' }}
                          error={!!error?.message}
                          helperText={error?.message}
                          value={value}
                          onChange={onChange}
                          margin="normal"
                          required
                          fullWidth
                          name={`iban.${index}.value`}
                          label="IBAN"
                          id={`iban.${index}.value`}
                          autoComplete="new-password"
                          size="small"
                        />
                      )}
                    />
                    <IconButton
                      aria-label="Delete"
                      size="large"
                      title="Delete IBAN"
                      type="button"
                      disabled={!!field.Id && userData?.Ibans.length === 1}
                      onClick={() => removeIbanHandler(field, index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                );
              })}
              <Button
                sx={{ color: theme.palette.info.main }}
                type="button"
                // disabled={userData?.Ibans.length === 0}
                onClick={() => append(emptyIban)}
              >
                + Add new IBAN
              </Button>
              <Grid item>
                {errors.iban ? (
                  <Typography
                    align="left"
                    variant="caption"
                    color={theme.palette.error.main}
                    sx={{ margin: '4px 14px 0 14px', width: '100%' }}
                  >
                    {errors.iban.message}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TwoFaModalComponent
            disabled={Object.keys(errors).length !== 0}
            onComplete={confirm2FaHandle}
            endIcon={<SaveIcon />}
            loading={isLoadingUpdateUser || userDataIsFetching}
            loadingPosition="end"
            variant="contained"
            skip={!userData?.isChangeSettings2faEnabled}
            sx={{ mt: 3, ml: 1 }}
          >
            Update
          </TwoFaModalComponent>
        </Box>
      </form>
    </Paper>
  );
}
