import { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RegisterFormValues } from 'types/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useRegisterMutation } from 'services';
import { useServerError } from 'hooks';
import { emailScheme, passwordConfirmScheme, passwordScheme } from 'utils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PhoneInput from 'react-phone-input-2';
import { theme } from '../../assets/theme';
import { styled } from '@mui/system';
import { PATH_REGISTER } from '../../constants/spa-routes';
import { useSendVerificationCode } from '../../hooks/useSendVerificationCode';
import { TwoFactorTypesEnum } from '../../types/user.interface';

const StyledPhoneInput = styled(PhoneInput)(({ theme: styledTheme }) => ({
  '&.react-tel-input .form-control:focus': {
    borderColor: styledTheme.palette.primary.main,
    boxShadow: `0px 0px 0px 1px ${styledTheme.palette.primary.main}`,
  },
}));

type RegisterPageProps = {};
export const AuthRegisterFormPage: FC<RegisterPageProps> = () => {
  const navigate = useNavigate();
  const [
    registerRequest,
    { isLoading, isError: isRegisterError, error: registerError },
  ] = useRegisterMutation();

  const { sendEmailCode } = useSendVerificationCode();

  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password'
  );
  const [confirmPasswordType, setConfirmPasswordType] = useState<
    'password' | 'text'
  >('password');
  const [lowercaseEmail, setLowercaseEmail] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm<RegisterFormValues>({
    defaultValues: {
      name: '',
      email: '',
      lastName: '',
      middleName: '',
      password: '',
      passwordConfirmation: '',
      phone: '',
    },
    resolver: yupResolver(
      object({
        name: string().required().min(4),
        email: emailScheme({ required: true }),
        lastName: string().required().min(4),
        middleName: string().nullable(),
        password: passwordScheme({ required: true }),
        passwordConfirmation: passwordConfirmScheme({ required: true }),
        phone: string().required().min(4),
      })
    ),
  });

  const { phone } = getValues();

  const phoneHandler = (phoneNumber: string, isErrorMessage: boolean) => {
    setValue('phone', `+${phoneNumber}`);
    if (isErrorMessage) {
      trigger('phone');
    }
  };

  const inputPhoneStyle = {
    width: '100%',
    padding: '18px 14px 18px 84px',
    borderColor: !!errors?.phone?.message ? theme.palette.error.main : '',
  };

  const navigateToVerify = (email: string, phoneNumber: string) => {
    sendEmailCode({ email });
    navigate(PATH_REGISTER.REGISTER_EMAIL_VERIFY, {
      state: {
        email,
        phone: phoneNumber,
        verifyType: 'register',
        twoFactorType: TwoFactorTypesEnum.EMAIL,
      },
    });
  };

  const onSubmit: SubmitHandler<RegisterFormValues> = (data) =>
    registerRequest({
      name: data.name,
      email: data.email,
      lastName: data.lastName,
      password: data.password,
      phone: data.phone,
      middleName: data.middleName,
    })
      .unwrap()
      .then(() => navigateToVerify(data.email, data.phone))
      .catch(() => null);

  const changeEmailHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setLowercaseEmail(e.target.value.toLowerCase());

  const changePassTypeHandle = () =>
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));

  const changeConfirmPassTypeHandle = () =>
    setConfirmPasswordType((prev) =>
      prev === 'password' ? 'text' : 'password'
    );

  useServerError({ isError: isRegisterError, error: registerError });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        {...register('name')}
        error={!!errors?.name?.message}
        helperText={errors?.name?.message}
        margin="normal"
        required
        fullWidth
        id="v"
        label="Name"
        name="name"
        autoComplete="given-name"
        autoFocus
      />
      <TextField
        {...register('middleName')}
        error={!!errors?.middleName?.message}
        helperText={errors?.middleName?.message}
        margin="normal"
        fullWidth
        id="middleName"
        label="Middle Name"
        name="middleName"
        autoComplete="given-name"
      />
      <TextField
        {...register('lastName')}
        error={!!errors?.lastName?.message}
        helperText={errors?.lastName?.message}
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="family-name"
      />
      <TextField
        {...register('email')}
        error={!!errors?.email?.message}
        helperText={errors?.email?.message}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        value={lowercaseEmail}
        onChange={changeEmailHandler}
        name="email"
        type="email"
        autoComplete="email"
        sx={{ marginBottom: '24px' }}
      />
      <Controller
        control={control}
        name="phone"
        render={({ fieldState: { error } }) => (
          <>
            <StyledPhoneInput
              country={'us'}
              value={phone}
              onChange={(phoneNumber) =>
                phoneHandler(phoneNumber, !!error?.message)
              }
              inputClass="phone-input"
              inputStyle={inputPhoneStyle}
            />
            {!!error?.message && (
              <Typography
                align="left"
                variant="caption"
                color={theme.palette.error.main}
                sx={{
                  margin: '4px 14px 0 14px',
                  width: '100%',
                  display: 'block',
                }}
              >
                {error?.message}
              </Typography>
            )}
          </>
        )}
      />
      <TextField
        {...register('password')}
        error={!!errors?.password?.message}
        helperText={errors?.password?.message}
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={passwordType}
        id="password"
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={changePassTypeHandle}>
                {passwordType === 'text' ? (
                  <RemoveRedEyeOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        {...register('passwordConfirmation')}
        error={!!errors?.passwordConfirmation?.message}
        helperText={errors?.passwordConfirmation?.message}
        margin="normal"
        required
        fullWidth
        name="passwordConfirmation"
        label="Repeat password"
        type={confirmPasswordType}
        id="passwordConfirmation"
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={changeConfirmPassTypeHandle}>
                {confirmPasswordType === 'text' ? (
                  <RemoveRedEyeOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        type="submit"
        disabled={isLoading}
        endIcon={
          isLoading ? (
            <CircularProgress color="secondary" size={20} />
          ) : (
            <SaveIcon />
          )
        }
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign Up
      </Button>
      <Grid container>
        <Grid item>
          <Link variant="body2" component={NavLink} to="/auth">
            Already has account
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
