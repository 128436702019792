import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Crypto } from 'store/wallet/types';
import { Cryptocurrencies } from 'pages/exchange/exchange.page';

import { ExchangeForm } from '../..';

type ExchangeModalProps = {
  isOpen: boolean;
  prices: Cryptocurrencies;
  symbol: Crypto;
  onClose: () => void;
};

export const ExchangeModal: FC<ExchangeModalProps> = ({
  isOpen,
  onClose,
  prices,
  symbol,
}) => {
  const onModalClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onModalClose} fullWidth>
      <DialogTitle>Exchange</DialogTitle>
      <DialogContent>
        <ExchangeForm
          symbol={symbol}
          prices={prices}
          onClose={onClose}
          withCloseButton
          loadingSx={{ top: 0, left: 0 }}
        />
      </DialogContent>
    </Dialog>
  );
};
