import { Paper, Typography, styled, Box } from '@mui/material';
import React, { FC } from 'react';
import { useGetBalanceHistoryQuery } from 'services';
import { theme } from '../../../assets/theme';
import { formatPrice } from '../../../utils';
import { CurrenciesSelector } from '../../../shared/currencies/currencies.selector';
import { useAppSelector } from '../../../store';
import { useBalance, useServerError, useDailyFilter } from '../../../hooks';
import { EstimatedBalanceChart } from './estimated.balance.chart';
import { Loader } from '../../../shared';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

export const ChartBlock: FC = () => {
  const { selectedCurrency } = useAppSelector((s) => s.uiReducer);

  const { total } = useBalance();

  const { DailyFilterButtons, period } = useDailyFilter({});

  const {
    data: balanceHistoryData,
    isError: balanceHistoryIsError,
    error: balanceHistoryError,
    isFetching: balanceHistoryIsFetching,
  } = useGetBalanceHistoryQuery({
    lastDays: period,
  });

  useServerError({
    isError: balanceHistoryIsError,
    error: balanceHistoryError,
  });

  return (
    <Section sx={{ mt: 4 }} elevation={4}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography variant="h5" color="inherit" noWrap align="left">
          Estimated Balance
        </Typography>
        <Box
          sx={{
            width: { sm: 'auto', xs: '100%' },
            justifyContent: 'start',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="h5"
            color="inherit"
            noWrap
            align="left"
            sx={{
              fontSize: { sm: '2em', xs: '1.5em' },
              display: 'flex',
              gap: '10px',
            }}
          >
            {formatPrice(total * selectedCurrency.rate, 2)}
            <CurrenciesSelector currency="USD" />
          </Typography>
        </Box>
        <DailyFilterButtons />
        <Typography
          color={theme.palette.grey['400']}
          noWrap
          align="left"
          whiteSpace={'pre-wrap'}
        >
          The daily assets change data will be updated before 08:00 UTC the next
          day
        </Typography>
        {balanceHistoryIsFetching ? (
          <Loader pad={15} />
        ) : (
          <EstimatedBalanceChart balanceHistoryData={balanceHistoryData} />
        )}
      </Box>
    </Section>
  );
};
