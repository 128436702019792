// created by Artem
import { FC } from 'react';
import {
  Button,
  FormHelperText,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNotification } from 'hooks';
import { formatPrice } from 'utils';

type OperationHistoryRowComponentProps = {
  data: any;
};
export const OperationHistoryRowComponent: FC<
  OperationHistoryRowComponentProps
> = ({ data }) => {
  const { showNotification } = useNotification();
  const copyHandle = () => {
    navigator?.clipboard?.writeText?.(`${data?.txHash || ''}`);
    showNotification('Hash was copied to the clipboard!', 'info');
  };

  return (
    <TableRow>
      <TableCell>#{data?.id}</TableCell>
      <TableCell sx={{ textTransform: 'capitalize' }}>
        {data?.type?.slice(0, -1)}
      </TableCell>
      <TableCell>{data?.status}</TableCell>
      <AmountRow data={data} />
      <TableCell>
        <Typography paragraph m={0}>
          {data?.date}
        </Typography>
        {data?.time && <small>{data.time}</small>}
      </TableCell>
      <TableCell>
        {data?.txHash && (
          <Button
            variant="outlined"
            onClick={copyHandle}
            startIcon={<ContentCopyIcon />}
          >
            Hash
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const AmountRow: FC<{ data: any } & TableCellProps> = ({ data, ...props }) => {
  switch (data?.type) {
    case 'withdrawals':
      return (
        <TableCell {...props}>
          <Typography display="flex" component="div">
            {data?.total}
            <FormHelperText sx={{ ml: 1 }}>{data?.fiat}</FormHelperText>
          </Typography>
          <ArrowDownwardIcon />
          <FormHelperText>
            {data?.User?.iban?.substring(0, data?.User?.iban?.length / 2)}...
          </FormHelperText>
        </TableCell>
      );

    case 'exchanges':
      return (
        <TableCell {...props}>
          <Typography display="flex" component="div">
            {Number(data?.assetAmount) || 0}
            <FormHelperText sx={{ ml: 1 }}>{data?.asset}</FormHelperText>
          </Typography>
          <ArrowDownwardIcon />
          <Typography display="flex" component="div">
            {Number(data?.fiatAmount) || 0}
            <FormHelperText sx={{ ml: 1 }}>{data?.fiat}</FormHelperText>
          </Typography>
          {data?.fee && (
            <FormHelperText>
              Fee: {formatPrice(data.fee)}
              {data?.fiat}
            </FormHelperText>
          )}
        </TableCell>
      );

    case 'deposits':
      return (
        <TableCell {...props}>
          <Typography display="flex" component="div">
            {data?.assetAmount}
            <FormHelperText sx={{ ml: 1 }}>{data?.asset}</FormHelperText>
          </Typography>
        </TableCell>
      );

    default:
      return null;
  }
};
