import { UserBalance, Fiat, Deposit } from 'store/wallet/types';

export type CryptoBalance = {
  asset: string;
  total: string;
  address: string;
};

export type FiatBalance = {
  fiat: string;
  total: string;
};

export type GetOriginalWalletBalanceResponse = {
  fiatBalances: FiatBalance[];
  cryptoBalances: CryptoBalance[];
  total: number;
};

export type Balance = {
  total: string;
  fiat: string;
};

export type SystemBalance = {
  systemBalance: Balance[];
};

export type SendCryptoPayload = {
  total: number;
  cryptoAsset: string;
  walletAddress: string;
};

export type ExchangePayload = {
  assetId: string;
  amount: number;
  fiat: Fiat;
  code?: string;
};

export type SendFiatPayload = {
  total: number;
  fiat: string;
  ibanId: number;
  code?: string;
};

export type MappedWalletBalanceResponse = UserBalance;

export const enum EventTypeEnum {
  EXCHANGE = 'EXCHANGE',
  DEPOSIT = 'DEPOSIT',
  SEND_CRYPTO_AMOUNT = 'SEND_CRYPTO_AMOUNT',
  SEND_FIAT_AMOUNT = 'SEND_FIAT_AMOUNT',
  WITHDRAW_CRYPTO = 'WITHDRAW_CRYPTO',
  WITHDRAW_FIAT = 'WITHDRAW_FIAT',
  WITHDRAW_SUBMIT = 'WITHDRAW_SUBMIT',
}

export type DepositInfo = {
  type: EventTypeEnum.DEPOSIT;
  asset: string;
  assetAmount: {
    amount: number;
    requestedAmount: number;
    netAmount: number;
    amountUSD: number | null;
  };
};

export type ExchangeInfo = Omit<Deposit, 'type'> & {
  type: EventTypeEnum.EXCHANGE;
};

export type SendCryptoInfo = {
  type: EventTypeEnum.SEND_CRYPTO_AMOUNT;
  asset: string;
  assetAmount: string;
  walletAddress: string;
};

export type SendFiatInfo = {
  type: EventTypeEnum.SEND_FIAT_AMOUNT;
  fiat: string;
  amount: string;
  iban: string;
};

export type NotificationData =
  | DepositInfo
  | ExchangeInfo
  | SendCryptoInfo
  | SendFiatInfo;

export type Notification = {
  id: number;
  type: EventTypeEnum;
  success: boolean;
  message: string;
  createdAt: Date;
  additionalInfo: string;
  read: boolean;
};

export type NotificationsResponse = Notification[];
