import { FC, memo, useEffect, useState } from 'react';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useChange2faMutation, useGetUserQuery } from '../../../services';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useNotification, useServerError } from '../../../hooks';
import { TwoFactorTypesEnum } from '../../../types/user.interface';

type ChangeLoginTypeProps = {
  open: boolean;
  onClose: () => void;
};

const typesVariant = [
  TwoFactorTypesEnum.EMAIL,
  TwoFactorTypesEnum.SMS,
  TwoFactorTypesEnum.GOOGLE_AUTHENTICATOR,
];

export const ChangeLoginType: FC<ChangeLoginTypeProps> = memo(
  ({ open, onClose }) => {
    const [type, setType] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
      setType(event.target.value as string);
    };
    const { showNotification } = useNotification();

    const { data: userData, refetch: refetchUserData } = useGetUserQuery();

    const [
      change2Fa,
      {
        isLoading: change2FaLoading,
        isError: change2FaIsError,
        error: change2FaError,
        isSuccess: change2FaIsSuccess,
      },
    ] = useChange2faMutation();

    const onSubmit = () => {
      change2Fa({
        type: typesVariant[+type],
      });
    };

    useServerError({ isError: change2FaIsError, error: change2FaError });

    useEffect(() => {
      setType('');
    }, [onClose]);

    useEffect(() => {
      if (userData) {
        setType(typesVariant.indexOf(userData?.twoFactorType).toString());
      }
    }, [userData, open]);

    useEffect(() => {
      if (change2FaIsSuccess) {
        onClose();
        refetchUserData();
        showNotification('2FA type was changed!', 'success');
      }
    }, [change2FaIsSuccess]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="Change 2FA type" />
          <Divider />
          <Typography m={1} variant="h5">
            Select your verification type
          </Typography>
          <Divider />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Verification type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Verification type"
                onChange={handleChange}
              >
                <MenuItem disabled={!userData?.emailVerified} value={0}>
                  Email
                </MenuItem>
                <MenuItem disabled={!userData?.phoneVerified} value={1}>
                  Sms
                </MenuItem>
                <MenuItem
                  disabled={!userData?.isGoogleAuthenticatorSetUp}
                  value={2}
                >
                  Google
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            onClick={onSubmit}
            endIcon={<SaveIcon />}
            loadingPosition="end"
            loading={change2FaLoading}
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            Change & Save
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  }
);
