import { Stack, Typography, Paper, Box } from '@mui/material';
import { FC } from 'react';
import { Container } from 'shared/container/container';
import { useGetTransactionsQuery } from 'services';
import { useServerError, useTransactionsHistoryFilter } from 'hooks';
import {
  LoadingComponent,
  OperationHistoryFiltersComponent,
  OperationHistoryTableComponent,
} from 'shared';
import { AccountStatement } from './account.statement';

export const OperationHistoryPage: FC = () => {
  const { data, isLoading, isError, error } = useGetTransactionsQuery({});
  const { data: transactions, onFilter } = useTransactionsHistoryFilter(data);
  useServerError({ isError, error });

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-between', xs: 'center' },
          flexWrap: 'wrap',
          width: '100%',
          gap: '20px',
        }}
      >
        <Box>
          <Typography variant="h4" color="inherit">
            Operation History
          </Typography>
          <Typography sx={{ mt: 2 }}>There is a list of operations.</Typography>
        </Box>
        <AccountStatement />
      </Box>
      <Stack spacing={1} sx={{ position: 'relative', width: '100%' }}>
        {isLoading && <LoadingComponent byContainer size={100} />}

        <OperationHistoryFiltersComponent onFilter={onFilter} />
        <Paper elevation={4}>
          <OperationHistoryTableComponent data={transactions} />
        </Paper>
      </Stack>
    </Container>
  );
};
