import React, { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  Box,
  DialogActions,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';

import { useBalance, useNotification } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { EXCHANGE_COIN_LIST, TwoFaModalComponent } from 'shared';
import { useAppDispatch } from 'store';
import { Crypto } from 'store/wallet/types';
import { useGetUserQuery, useSendCryptoMutation } from 'services';
import { setWithdrawalWaiting } from 'store/ui';

type SendCryptoModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  withCloseButton?: boolean;
  sendBtnFullWidth?: boolean;
  selectedSymbol?: string | Crypto;
};

type SendCryptoFormData = {
  amount: number;
  address: string;
};

export const WithdrawCryptoForm: FC<SendCryptoModalProps> = ({
  onClose = () => {},
  withCloseButton = false,
  sendBtnFullWidth = false,
  selectedSymbol = 'USDT',
}) => {
  const [withdraw] = useSendCryptoMutation(undefined);
  const dispatch = useAppDispatch();
  const { balance } = useBalance();
  const [selectedAsset, setSelectedAsset] = useState<Crypto | string>(
    selectedSymbol
  );
  const { showNotification } = useNotification();
  const { data: userData } = useGetUserQuery();
  const userBalance =
    (balance?.deposit?.[selectedAsset as Crypto]?.total as number) || 0;

  const handleAssetChange = (event: SelectChangeEvent) => {
    setSelectedAsset(event.target.value as Crypto);
  };

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .transform((value) => (isNaN(value) ? undefined : value))
      .default(undefined)
      .positive('Amount must be a positive number')
      .required('Amount is required')
      .max(userBalance, `Your limit is: ${userBalance} ${selectedAsset}.`),
    address: yup.string().required('Wallet address is required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SendCryptoFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const handleWithdrawal = async (data: SendCryptoFormData, code: string) => {
    const { amount, address } = data;
    const crypto = balance?.deposit?.[selectedAsset as Crypto];
    if (!crypto) {
      return;
    }

    await withdraw({
      cryptoAsset: crypto?.value,
      total: Number(amount),
      walletAddress: address,
      ...(code === '' ? {} : { code }),
    });
    dispatch(setWithdrawalWaiting({ status: true }));
    showNotification(
      `Withdrawal request of ${amount} ${selectedAsset} was successful.`
    );
    onClose();
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;
    handleSubmit(async (data) => await handleWithdrawal(data, code))();
  };

  const sendCryptoAmount = watch('amount');

  const isMoreThenLimit = useMemo(() => {
    const limit = Number(userData?.sendCryptoLimit || 0);
    return limit === 0 ? false : limit < sendCryptoAmount;
  }, [sendCryptoAmount]);

  const skip2fa = useMemo(() => {
    if (userData?.isSendCrypto2faEnabled) {
      return false;
    }

    return !(!userData?.isSendCrypto2faEnabled && isMoreThenLimit);
  }, [isMoreThenLimit, userData?.isSendCrypto2faEnabled]);

  return (
    <form>
      <TextField
        fullWidth
        margin="normal"
        label="Amount"
        type="number"
        {...register('amount', { required: 'Amount is required' })}
        error={!!errors.amount}
        helperText={errors.amount?.message}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="from-currency-label">From Currency</InputLabel>
        <Select
          label="From Currency"
          labelId="from-currency-label"
          id="from-currency-select"
          value={selectedAsset}
          onChange={handleAssetChange}
          defaultValue={selectedAsset}
          renderValue={(selected) =>
            EXCHANGE_COIN_LIST.filter((ec) => ec.symbol === selected).map(
              (ec) => (
                <Box
                  key={ec.id}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Box width={30} height={30} mr={1}>
                    {ec.icon}
                  </Box>
                  {ec.symbol}
                </Box>
              )
            )
          }
        >
          {EXCHANGE_COIN_LIST.map((ec) => (
            <MenuItem
              key={ec.id}
              value={ec.symbol}
              selected={selectedAsset === ec.symbol}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box width={30} height={30} mr={1}>
                {ec.icon}
              </Box>
              {ec.symbol}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        margin="normal"
        fullWidth
        label="Wallet Address"
        {...register('address', { required: 'Wallet address is required' })}
        error={!!errors.address}
        helperText={errors.address?.message}
      />
      <DialogActions>
        {withCloseButton && (
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
        )}
        <TwoFaModalComponent
          disabled={Object.keys(errors).length !== 0}
          type="button"
          variant="contained"
          color="primary"
          onComplete={confirm2FaHandle}
          skip={skip2fa}
          fullWidth={sendBtnFullWidth}
        >
          Send Crypto
        </TwoFaModalComponent>
      </DialogActions>
    </form>
  );
};
