import { transformTokenResponse } from 'utils';
import { IUser } from 'types/user.interface';
import {
  AuthGenerate2FaTotp,
  AuthGenerate2FaTotpVerify,
  AuthVerifyFormValues,
  LoginFormValues,
  LoginResponse,
  RegisterFormValues,
} from 'types/auth.interface';
import { PATH_AUTH_API, PATH_USER_API } from 'constants/api-routes';
import { baseApi } from './base.api';

export const authService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginFormValues>({
      query: (body) => ({ url: PATH_AUTH_API.LOGIN, method: 'POST', body }),
      invalidatesTags: ['User'],
    }),
    register: build.mutation<IUser, RegisterFormValues>({
      query: (body) => ({ url: PATH_AUTH_API.REGISTER, method: 'POST', body }),
    }),
    verify: build.mutation<{ message: string }, AuthVerifyFormValues>({
      query: ({ code, type = 'register', ...body }) => ({
        url: `${
          type === 'register' ? PATH_AUTH_API.REGISTER : PATH_AUTH_API.LOGIN
        }/${code}`,
        method: 'POST',
        body,
      }),
      transformResponse: transformTokenResponse,
      invalidatesTags: ['User'],
    }),
    getUser: build.query<IUser, void>({
      query: () => ({ url: PATH_USER_API.PROFILE, method: 'GET' }),
      providesTags: ['User'],
    }),
    forgotPass: build.mutation<any, { email: string }>({
      query: (body) => ({
        url: PATH_AUTH_API.FORGOT_PASSWORD,
        method: 'POST',
        body,
      }),
    }),
    resetPass: build.mutation<
      any,
      { email: string; newPassword: string; code: string }
    >({
      query: (body) => ({
        url: PATH_AUTH_API.RESET_PASSWORD,
        method: 'POST',
        body,
      }),
      transformResponse: transformTokenResponse,
      invalidatesTags: ['User'],
    }),
    '2faSendCode': build.query<any, { id?: string | number }>({
      query: (body) => ({
        url: PATH_AUTH_API.SEND_2FA_CODE,
        method: 'POST',
        body,
      }),
    }),
    '2faVerify': build.mutation<any, any>({
      query: (body) => ({
        url: PATH_AUTH_API.VERIFY_2FA_CODE,
        method: 'POST',
        body,
      }),
    }),
    '2faTotpGenerate': build.mutation<AuthGenerate2FaTotp, {}>({
      query: (body) => ({
        url: PATH_AUTH_API.GENERATE_2FA_TOTP,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    '2faTotpLoginVerify': build.mutation<
      AuthGenerate2FaTotpVerify,
      { email: string; password: string; code: string }
    >({
      query: (body) => ({
        url: PATH_AUTH_API.VERIFY_LOGIN_2FA_TOTP,
        method: 'POST',
        body,
      }),
      transformResponse: transformTokenResponse,
      invalidatesTags: ['User'],
    }),
    '2faTotpVerify': build.mutation<
      AuthGenerate2FaTotpVerify,
      { code: string }
    >({
      query: (body) => ({
        url: PATH_AUTH_API.VERIFY_2FA_TOTP,
        method: 'POST',
        body,
      }),
      transformResponse: transformTokenResponse,
      invalidatesTags: ['User'],
    }),
    sendPhoneCode: build.mutation<AuthGenerate2FaTotpVerify, { phone: string }>(
      {
        query: (body) => ({
          url: PATH_AUTH_API.SEND_PHONE_CODE,
          method: 'POST',
          body,
        }),
      }
    ),
    sendEmailCode: build.mutation<AuthGenerate2FaTotpVerify, { email: string }>(
      {
        query: (body) => ({
          url: PATH_AUTH_API.SEND_EMAIL_CODE,
          method: 'POST',
          body,
        }),
      }
    ),
    verifyPhoneCode: build.mutation<
      AuthGenerate2FaTotpVerify,
      { code: string; phone: string }
    >({
      query: ({ code, phone }) => ({
        url: `${PATH_AUTH_API.VERIFY_PHONE_CODE}/${code}`,
        method: 'POST',
        body: {
          phone,
        },
      }),
      transformResponse: transformTokenResponse,
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useRegisterMutation,
  useVerifyMutation,
  useGetUserQuery,
  useLoginMutation,
  useForgotPassMutation,
  useResetPassMutation,
  use2faSendCodeQuery,
  use2faVerifyMutation,
  use2faTotpGenerateMutation,
  use2faTotpVerifyMutation,
  useSendPhoneCodeMutation,
  useVerifyPhoneCodeMutation,
  use2faTotpLoginVerifyMutation,
  useSendEmailCodeMutation,
} = authService;
