import React, { useState, FC, useMemo, useEffect } from 'react';
import {
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Crypto } from 'store/wallet/types';
import { EXCHANGE_COIN_LIST } from 'shared';
import { useBalance } from 'hooks';
import { QrCode } from 'components/qr_code/qr_code';

type WalletFormProps = {
  selectedSymbol?: string | Crypto;
};

export const WalletForm: FC<WalletFormProps> = ({
  selectedSymbol = 'USDT',
}) => {
  const [selectedAsset, setSelectedAsset] = useState<Crypto | string>(
    selectedSymbol
  );
  const [isCopied, setIsCopied] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const { balance } = useBalance();

  const handleCopyToClipboard = () => {
    const wallet = balance?.deposit?.[selectedAsset as Crypto]?.address || '';
    navigator.clipboard.writeText(wallet);
    setIsCopied(true);
  };

  const handleAssetChange = (event: SelectChangeEvent) => {
    setSelectedAsset(event.target.value as Crypto);
    setIsCopied(false);
  };

  const qrCodeWalletAddress = useMemo(() => {
    const address = balance?.deposit?.[selectedAsset as Crypto]?.address || '';
    const coinInfo = EXCHANGE_COIN_LIST.find((c) => c.symbol === selectedAsset);
    return `${coinInfo?.protocol}:${address}?network=${coinInfo?.network}`;
  }, [selectedAsset]);

  useEffect(() => {
    if (balance && balance?.deposit?.[selectedAsset as Crypto]?.address) {
      setWalletAddress(
        balance?.deposit?.[selectedAsset as Crypto]?.address as string
      );
    }
  }, [balance?.deposit?.[selectedAsset as Crypto]?.address]);

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="from-currency-label">From Currency</InputLabel>
        <Select
          label="From Currency"
          labelId="from-currency-label"
          id="from-currency-select"
          value={selectedAsset}
          onChange={handleAssetChange}
          defaultValue={selectedAsset}
          renderValue={(selected) =>
            EXCHANGE_COIN_LIST.filter((ec) => ec.symbol === selected).map(
              (ec) => (
                <Box
                  key={ec.id}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Box width={30} height={30} mr={1}>
                    {ec.icon}
                  </Box>
                  {ec.symbol}
                </Box>
              )
            )
          }
        >
          {EXCHANGE_COIN_LIST.map((ec) => (
            <MenuItem
              key={ec.id}
              value={ec.symbol}
              selected={selectedAsset === ec.symbol}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box width={30} height={30} mr={1}>
                {ec.icon}
              </Box>
              {ec.symbol}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        margin="normal"
        fullWidth
        label="Wallet Address"
        value={walletAddress}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <Tooltip title={isCopied ? 'Copied!' : 'Copy'} placement="top">
              <IconButton
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center" my={2}>
        <Typography>Or</Typography>
        <Typography align="left">Scan QR Code</Typography>
      </Box>
      <QrCode url={qrCodeWalletAddress} />
    </>
  );
};
