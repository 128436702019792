import { Crypto, Fiat } from 'store/wallet/types';
import { CryptoInstruments } from 'pages/exchange/exchange.page';

export const getNormalizedCrypto = (
  symbol: Crypto,
  selectedCurrency: Fiat
): CryptoInstruments => {
  const transformedSymbols: Record<string, string> = {
    USDC: 'USC',
    MATIC: 'MAT',
    USDT: 'UST',
  };
  const cryptoPart: string = transformedSymbols[symbol] || symbol;
  return `${cryptoPart}${selectedCurrency}` as CryptoInstruments;
};
