import { FC, useEffect } from 'react';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
  Box,
} from '@mui/material';
import { setCurrency } from 'store/ui';
import { useAppDispatch, useAppSelector } from 'store';
import { Fiat } from 'store/wallet/types';
import { Loader } from '../../shared';
import { useB2C2RatesContext } from 'context/B2C2RatesContext';
import { CryptoInstruments } from 'pages/exchange/exchange.page';

type CurrenciesSelectorProps = {
  disabled?: boolean;
  currency?: Fiat;
};
export const CurrenciesSelector: FC<CurrenciesSelectorProps> = ({
  disabled = false,
  currency,
}) => {
  const { selectedCurrency, isCurrencyLoading } = useAppSelector(
    (s) => s.uiReducer
  );
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const rates = useB2C2RatesContext();

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    const newCurrency = event.target.value as Fiat;
    const rate =
      newCurrency === 'USD'
        ? 1
        : rates[`USD${newCurrency}` as CryptoInstruments]?.price;
    dispatch(
      setCurrency({
        symbol: newCurrency,
        rate: Number(rate),
      })
    );
  };

  useEffect(() => {
    if (currency) {
      const propsRate =
        currency === 'USD'
          ? 1
          : rates[`USD${currency}` as CryptoInstruments]?.price;
      dispatch(
        setCurrency({
          symbol: currency,
          rate: Number(propsRate),
        })
      );
    }
  }, [currency]);

  return currency ? (
    <Box>{currency}</Box>
  ) : (
    <Select
      startAdornment={
        isCurrencyLoading && <Loader sx={{ paddingRight: '5px' }} size={15} />
      }
      value={selectedCurrency.symbol}
      onChange={handleCurrencyChange}
      disableUnderline
      disabled={disabled}
      size="small"
      sx={{
        transition: '0.2s',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
      }}
    >
      <MenuItem value="USD">USD</MenuItem>
      <MenuItem value="EUR">EUR</MenuItem>
      <MenuItem value="GBP">GBP</MenuItem>
      {/* <MenuItem value="AED">AED</MenuItem> */}
    </Select>
  );
};
