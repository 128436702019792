import { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { useBalance, useNotification } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetUserQuery, useSendFiatMutation } from 'services';
import { useAppDispatch } from 'store';
import { setWithdrawalWaiting } from 'store/ui';
import { Fiat } from 'store/wallet/types';
import { TwoFaModalComponent } from 'shared';

type SendFiatModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type SendFiatFormData = {
  amount: number;
  currency: string;
  iban: string;
};

export const SendFiatModal: FC<SendFiatModalProps> = ({ isOpen, onClose }) => {
  const { balance } = useBalance();
  const [withdraw] = useSendFiatMutation(undefined);
  const dispatch = useAppDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState<Fiat>('USD');
  // const [code, setCode] = useState('');
  // const { TwoFaCode } = useAppSelector((s) => s.uiReducer);
  const { data: userData } = useGetUserQuery();
  const userBalance =
    (balance?.withdraw?.[selectedCurrency]?.total as number) || 0;
  const { showNotification } = useNotification();
  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .transform((value) => (isNaN(value) ? undefined : value))
      .default(undefined)
      .positive('Amount must be a positive number')
      .required('Amount is required')
      .max(userBalance, `Your limit is: ${userBalance} ${selectedCurrency}.`),
    iban: yup
      .string()
      .required('IBAN is required')
      .test('valid-iban', 'Invalid IBAN', (value) => {
        const ibanPattern = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
        return ibanPattern.test(value);
      })
      .test('is-added', 'IBAN is not added to account', (value) => {
        return (
          userData?.Ibans?.find((iban) => iban.account === value) !== undefined
        );
      })
      .test('is-added', 'IBAN is not verified', (value) => {
        const currIban = userData?.Ibans?.find((ib) => ib.account === value);
        return currIban?.isVerified;
      }),
  });

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm<SendFiatFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const handleWithdrawal = async (data: SendFiatFormData, code: string) => {
    const { amount, iban } = data;
    const selectedIBAN = userData?.Ibans?.find((val) => val.account === iban);
    if (!selectedIBAN) {
      return;
    }
    await withdraw({
      fiat: selectedCurrency,
      total: Number(amount),
      ibanId: selectedIBAN.id,
      ...(!code ? {} : { code }),
    });
    dispatch(setWithdrawalWaiting({ status: true }));
    showNotification(
      `Withdrawal request of ${amount} ${selectedCurrency} was successful.`
    );
    onClose();
  };

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setSelectedCurrency(event.target.value as Fiat);
    trigger();
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;
    handleSubmit(async (data) => await handleWithdrawal(data, code))();
  };

  const withdrawAmount = watch('amount');

  const isMoreThenLimit = useMemo(() => {
    const limit = Number(userData?.withdrawLimit || 0);
    return limit === 0 ? false : limit < withdrawAmount;
  }, [withdrawAmount]);

  const skip2fa = useMemo(() => {
    if (userData?.isWithdraw2faEnabled) {
      return false;
    }

    if (!userData?.isWithdraw2faEnabled && isMoreThenLimit) {
      return false;
    }

    return true;
  }, [isMoreThenLimit, userData?.isWithdraw2faEnabled]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Withdraw Fiat</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            fullWidth
            margin="normal"
            label="Amount"
            type="number"
            {...register('amount', { required: 'Amount is required' })}
            error={!!errors.amount}
            helperText={errors.amount?.message}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="currency-label">Currency</InputLabel>
            <Select
              labelId="currency-label"
              id="currency-select"
              label="Currency"
              {...register('currency', {
                required: 'Currency is required',
              })}
              onChange={handleCurrencyChange}
              value={selectedCurrency}
              error={!!errors.currency}
              defaultValue="USD"
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem disabled value="EUR">
                EUR
              </MenuItem>
              <MenuItem disabled value="GBP">
                GBP
              </MenuItem>
              {/* <MenuItem disabled value="AED">
                AED
              </MenuItem> */}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="IBAN"
            {...register('iban', { required: 'IBAN is required' })}
            error={!!errors.iban}
            helperText={errors.iban?.message}
          />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <TwoFaModalComponent
              disabled={Object.keys(errors).length !== 0}
              type="button"
              variant="contained"
              color="primary"
              onComplete={confirm2FaHandle}
              skip={skip2fa}
            >
              Send Request
            </TwoFaModalComponent>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
